import {
  Flex,
  HStack,
  Skeleton,
  SkeletonText,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tr,
  WhiteCard,
} from '@elkaso-app/web-design';

const HeaderSkeleton = () => {
  return (
    <Flex p='md' justify='space-between' align='center'>
      <Skeleton w='200px' h='20px' />
      <HStack spacing='sm'>
        <Skeleton boxSize='49px' borderRadius='base' />
        <Skeleton boxSize='49px' borderRadius='base' />
      </HStack>
    </Flex>
  );
};

const ContentSkeleton = () => {
  const noOfRows = Array.from(Array(12).keys());

  return (
    <TableContainer>
      <Table size='md'>
        <Tbody>
          {noOfRows.map((row) => (
            <Tr key={row}>
              <Td>
                <SkeletonText noOfLines={1} />
              </Td>
              <Td>
                <SkeletonText noOfLines={1} />
              </Td>
              <Td>
                <SkeletonText noOfLines={1} />
              </Td>
              <Td>
                <SkeletonText noOfLines={1} />
              </Td>
              <Td>
                <SkeletonText noOfLines={1} />
              </Td>
              <Td>
                <SkeletonText noOfLines={1} />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

const FooterSkeleton = () => {
  return (
    <Flex p='md' justify='flex-end'>
      <HStack spacing='sm' me='md'>
        <Skeleton boxSize='32px' borderRadius='base' />
        <Skeleton boxSize='32px' borderRadius='base' />
        <Skeleton boxSize='32px' borderRadius='base' />
        <Skeleton boxSize='32px' borderRadius='base' />
      </HStack>
      <Skeleton w='200px' h='48px' borderRadius='base' />
    </Flex>
  );
};

export const BaseTableContainerSkeleton = () => {
  return (
    <WhiteCard>
      <HeaderSkeleton />
      <ContentSkeleton />
      <FooterSkeleton />
    </WhiteCard>
  );
};
