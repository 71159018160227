import { Icon, IconProps } from '@elkaso-app/web-design';

export const UserIcon = (props: IconProps) => {
  return (
    <Icon width='16px' height='18px' viewBox='0 0 16 18' fill='#757575' {...props}>
      <path
        d='M15.3723 14.6894C14.9709 13.679 14.3885 12.7612 13.6574 11.9872C12.9285 11.2109 12.0651 10.592 11.1149 10.1646C11.1064 10.1601 11.0979 10.1578 11.0893 10.1533C12.4149 9.13568 13.2766 7.47814 13.2766 5.60804C13.2766 2.51005 10.9149 0 8 0C5.08513 0 2.72344 2.51005 2.72344 5.60804C2.72344 7.47814 3.58514 9.13568 4.91066 10.1555C4.90215 10.16 4.89364 10.1623 4.88513 10.1668C3.93194 10.5942 3.07663 11.207 2.34259 11.9894C1.61219 12.7641 1.02984 13.6817 0.627709 14.6917C0.232652 15.6805 0.0195891 16.7406 5.32039e-05 17.8146C-0.000514675 17.8387 0.00346833 17.8627 0.0117677 17.8852C0.020067 17.9077 0.0325146 17.9281 0.0483771 17.9454C0.0642397 17.9627 0.0831961 17.9764 0.104129 17.9858C0.125063 17.9952 0.14755 18 0.170265 18H1.44685C1.54047 18 1.61494 17.9209 1.61706 17.8236C1.65962 16.0779 2.31919 14.443 3.48514 13.2038C4.69151 11.9216 6.29363 11.2161 8 11.2161C9.70637 11.2161 11.3085 11.9216 12.5149 13.2038C13.6808 14.443 14.3404 16.0779 14.3829 17.8236C14.3851 17.9231 14.4595 18 14.5531 18H15.8297C15.8524 18 15.8749 17.9952 15.8959 17.9858C15.9168 17.9764 15.9358 17.9627 15.9516 17.9454C15.9675 17.9281 15.9799 17.9077 15.9882 17.8852C15.9965 17.8627 16.0005 17.8387 15.9999 17.8146C15.9787 16.7337 15.768 15.6822 15.3723 14.6894ZM8 9.49749C7.02341 9.49749 6.10427 9.09271 5.41278 8.35779C4.7213 7.62286 4.34045 6.64598 4.34045 5.60804C4.34045 4.5701 4.7213 3.59322 5.41278 2.85829C6.10427 2.12337 7.02341 1.71859 8 1.71859C8.97659 1.71859 9.89573 2.12337 10.5872 2.85829C11.2787 3.59322 11.6595 4.5701 11.6595 5.60804C11.6595 6.64598 11.2787 7.62286 10.5872 8.35779C9.89573 9.09271 8.97659 9.49749 8 9.49749Z'
        fill='inherit'
      />
    </Icon>
  );
};
