import { useCustomToast } from '@elkaso-app/web-design';
import http from 'apis/config/http-service';
import { useMutationWrapper } from 'apis/config/use-api-wrapper';
import type { AxiosError } from 'axios';
import { useLogout } from 'hooks/use-logout';
import type { UseMutationOptions, UseMutationResult } from 'react-query';

const endpoint = 'pos/v2/auth/foodics';
export const foodicsLoginEndpointIdentifier = endpoint;

interface BodyType {
  email: string;
  password: string;
}

type TData = Record<string, any>;
type TError = AxiosError;

export type TVariables = {
  body?: BodyType;
};

type Options = Omit<UseMutationOptions<TData, TError, TVariables, unknown>, 'mutationFn'>;
type ReturnType = UseMutationResult<TData, TError, TVariables>;

export function useFoodicsLoginApi(options?: Options): ReturnType {
  const toast = useCustomToast();
  const { onLogout } = useLogout();

  const mutationFn = async ({ body }: TVariables) => {
    try {
      const { data } = await http.post(endpoint, body);

      return data;
    } catch (error: any) {
      onLogout();

      if (!error.response.status || !error.response.data.message) throw error;

      toast({
        description: error.response.data.message,
        status: 'error',
      });

      throw error;
    }
  };

  return useMutationWrapper(mutationFn, { mutationKey: endpoint, ...options });
}
