import { Icon, IconProps } from '@elkaso-app/web-design';

export const PhoneIcon = (props: IconProps) => {
  return (
    <Icon width='16px' height='16px' viewBox='0 0 16 16' fill='#757575' {...props}>
      <path
        d='M14.1545 16H14.0499C1.96206 15.3046 0.245771 5.10154 0.00585927 1.98769C-0.0134719 1.74559 0.0151412 1.50205 0.090059 1.27102C0.164977 1.04 0.284727 0.826033 0.442448 0.641386C0.600169 0.456739 0.792761 0.305039 1.00919 0.194978C1.22562 0.0849176 1.46163 0.0186596 1.7037 5.76979e-07H5.09322C5.33962 -0.000238047 5.58042 0.073545 5.78443 0.211792C5.98843 0.35004 6.14624 0.546382 6.23741 0.775385L7.17245 3.07692C7.26248 3.30064 7.28482 3.5459 7.2367 3.78221C7.18858 4.01853 7.07212 4.23549 6.90178 4.40615L5.5915 5.72923C5.79617 6.89277 6.35317 7.96533 7.18715 8.80182C8.02113 9.63831 9.09183 10.1983 10.2544 10.4062L11.5893 9.08308C11.7625 8.91456 11.9814 8.80077 12.2188 8.7559C12.4562 8.71103 12.7015 8.73707 12.9242 8.83077L15.2433 9.76C15.4688 9.85408 15.6612 10.0132 15.7959 10.217C15.9307 10.4209 16.0017 10.6602 16 10.9046V14.1538C16 14.6435 15.8055 15.1131 15.4594 15.4593C15.1133 15.8055 14.6439 16 14.1545 16ZM1.85133 1.23077C1.68818 1.23077 1.53172 1.2956 1.41635 1.41101C1.30099 1.52642 1.23618 1.68294 1.23618 1.84615V1.89539C1.51915 5.53846 3.33386 14.1538 14.1176 14.7692C14.1984 14.7742 14.2794 14.7632 14.356 14.7368C14.4325 14.7104 14.5031 14.6692 14.5637 14.6154C14.6243 14.5617 14.6736 14.4965 14.709 14.4236C14.7443 14.3508 14.7649 14.2716 14.7697 14.1908V10.9046L12.4505 9.97538L10.685 11.7292L10.3897 11.6923C5.03785 11.0215 4.31197 5.66769 4.31197 5.61231L4.27506 5.31692L6.02211 3.55077L5.09937 1.23077H1.85133Z'
        fill='inherit'
      />
    </Icon>
  );
};
