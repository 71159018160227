import { Icon, IconProps } from '@elkaso-app/web-design';

export const NotificationsIcon = (props: IconProps) => {
  return (
    <Icon width='24px' height='24px' viewBox='0 0 24 24' fill='#757575' {...props}>
      <path
        d='M12 22C11.4694 21.9974 10.9612 21.7862 10.5851 21.4119C10.209 21.0377 9.99525 20.5305 9.99 20H13.99C13.9921 20.2674 13.9411 20.5325 13.84 20.78C13.7107 21.0767 13.5127 21.3385 13.2623 21.5437C13.0119 21.7488 12.7164 21.8915 12.4 21.96H12.353C12.2368 21.9842 12.1186 21.9976 12 22ZM20 19H4V17L6 16V10.5C5.94732 9.08912 6.26594 7.68913 6.924 6.44C7.24784 5.86727 7.68943 5.36971 8.21965 4.98015C8.74987 4.59058 9.35666 4.31787 10 4.18V2H14V4.18C16.579 4.794 18 7.038 18 10.5V16L20 17V19Z'
        fill='inherit'
      />
    </Icon>
  );
};
