import { BasePagination } from '@elkaso-app/web-design';
import { useGetAllPosBranchesApi } from 'apis/branches/use-get-all-pos-branches-api';
import { useGetKasoBranchesApi } from 'apis/branches/use-get-kaso-branches-api';
import { FilterOptions } from 'components/filter-options';
import { useSetPaginationParamsV2 } from 'hooks/use-set-pagination-params-v2';
import { Layout, Section } from 'layout';
import { ActionsCell, IBranchActionsCell } from 'pages/branches/components/actions-cell';
import { ISelectBranchCell, SelectBranchCell } from 'pages/branches/components/select-branch-cell';
import React from 'react';

import { BaseTable, BaseTableContainer } from 'components/base-table';
import {
  BaseTableSpinner,
  BaseTableTitle,
  ClearFilterButton,
  CustomTableCell,
  TableCell,
  TableHeader,
} from 'components/base-table/components';
import { NameFilterButton } from 'components/base-table/filters';
import { DownloadCSVButton } from 'components/download-csv-button';
import { UploadCSVButton } from 'components/upload-csv-button';
import { useVendorName } from 'hooks/use-vendor-name';
import { CellProps } from 'react-table';
import { getValidValue } from 'utils/get-valid-value';

export interface IBranch {
  kasoBranchId: string;
  kasoBranchName: string;
  posBranchesList: { id: string; name: string }[];
  posBranchName: string;
  posBranchId: string;
  reference: string;
}

const BranchesPage = () => {
  const setPaginationParams = useSetPaginationParamsV2();
  const { VendorName } = useVendorName();

  const {
    isLoading: isLoadingBranches,
    isFetching: isFetchingBranches,
    data: kasoBranchesApiData,
  } = useGetKasoBranchesApi();
  const { pagesCount, total, data: kasoBranchesData } = kasoBranchesApiData ?? {};

  const {
    isLoading: isLoadingPosBranches,
    isFetching: isFetchingPosBranches,
    data: posBranchesApiData,
  } = useGetAllPosBranchesApi();

  const isLoading = isLoadingBranches || isLoadingPosBranches;
  const isFetching = isFetchingBranches || isFetchingPosBranches;

  const mergedBranchesData = React.useMemo(() => {
    const branches: IBranch[] = [];

    kasoBranchesData?.map((kasoBranch: any) => {
      const posBranch = posBranchesApiData?.data?.find(
        (posBranch: any) =>
          getValidValue([posBranch?._id, posBranch?.id]) === kasoBranch?.posMappedBranch?.vendorBranchId
      );

      branches.push({
        kasoBranchId: kasoBranch?.id,
        kasoBranchName: kasoBranch?.nameEn,
        posBranchesList: posBranchesApiData?.data?.map((posBranch: any) => {
          return {
            id: getValidValue([posBranch?._id, posBranch?.id]),
            name: getValidValue([posBranch?.name]),
          };
        }),
        posBranchName: getValidValue([posBranch?.name]),
        posBranchId: getValidValue([posBranch?._id, posBranch?.id]),
        reference: getValidValue([posBranch?.reference]),
      });
    });

    return branches;
  }, [kasoBranchesApiData, posBranchesApiData]);

  const columns = React.useMemo(
    () => [
      {
        Header: <TableHeader name='Kaso Branch Name' filterButtonComponent={<NameFilterButton />} />,
        accessor: 'kaso_branch_name',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['kaso_branch_name']>) => (
          <TableCell value={value} fontWeight='semibold' fontSize='sm' color='red.500' />
        ),
      },
      {
        Header: <TableHeader name={`Select ${VendorName} Branch`} />,
        accessor: 'select_pos_branch',
        Cell: ({ row, value }: CellProps<any, IColumnsAccessorData['select_pos_branch']>) => (
          <CustomTableCell<ISelectBranchCell>
            as={SelectBranchCell}
            row={row}
            id={value?.kasoBranchId}
            options={value?.posBranchesList}
            defaultValue={{ id: value?.posBranchId, name: value?.posBranchName }}
          />
        ),
      },
      {
        Header: <TableHeader name={`${VendorName} Branch Name`} />,
        accessor: 'pos_branch_name',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['pos_branch_name']>) => (
          <TableCell value={value} fontWeight='semibold' fontSize='sm' color='blue.800' />
        ),
      },
      {
        Header: <TableHeader name='Reference' />,
        accessor: 'reference',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['reference']>) => (
          <TableCell value={value} fontSize='sm' color='gray.500' />
        ),
      },
      {
        Header: <TableHeader name='Actions' />,
        accessor: 'actions',
        Cell: ({ row, value }: CellProps<any, IColumnsAccessorData['actions']>) => (
          <CustomTableCell<IBranchActionsCell> as={ActionsCell} row={row} branch={value} />
        ),
      },
    ],
    []
  );

  interface IColumnsAccessorData {
    id: string;
    kaso_branch_name: string;
    select_pos_branch: IBranch;
    pos_branch_name: string;
    reference: string;
    actions: IBranch;
  }

  const columnsAccessorData = React.useMemo(
    () =>
      mergedBranchesData?.map((branch): IColumnsAccessorData => {
        return {
          id: branch?.kasoBranchId,
          kaso_branch_name: branch?.kasoBranchName || '--',
          select_pos_branch: branch,
          pos_branch_name: branch?.posBranchName || '--',
          reference: branch?.reference || '--',
          actions: branch,
        };
      }),
    [kasoBranchesApiData, posBranchesApiData]
  );

  return (
    <Layout type='sidebar_page_layout'>
      <Section name='content'>
        <BaseTableContainer isLoading={isLoading}>
          <BaseTableContainer.Header>
            <BaseTableContainer.Header.Left>
              <BaseTableTitle title='Branches' />
              <BaseTableSpinner isFetching={isFetching} />
            </BaseTableContainer.Header.Left>

            <BaseTableContainer.Header.Right>
              <DownloadCSVButton templateType='branches' disabled />
              <UploadCSVButton templateType='branches' />
              <ClearFilterButton />
            </BaseTableContainer.Header.Right>
          </BaseTableContainer.Header>

          <BaseTableContainer.TableWrapper>
            <FilterOptions
              options={[
                { name: 'All', value: '' },
                { name: 'Mapped', value: 'true' },
                { name: 'Unmapped', value: 'false' },
              ]}
              paramName='mapped'
              radioInputName='mapped'
            />
            <BaseTable columns={columns} data={columnsAccessorData} />
          </BaseTableContainer.TableWrapper>

          <BaseTableContainer.Footer>
            <BaseTableContainer.Footer.Left />
            <BaseTableContainer.Footer.Right>
              <BasePagination>
                <BasePagination.Pages pagesCount={pagesCount} setSearchParams={setPaginationParams} />
                <BasePagination.Limit total={total} setSearchParams={setPaginationParams} />
              </BasePagination>
            </BaseTableContainer.Footer.Right>
          </BaseTableContainer.Footer>
        </BaseTableContainer>
      </Section>
    </Layout>
  );
};

export default BranchesPage;
