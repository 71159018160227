import { Button, HStack, IconButtonWithTooltip } from '@elkaso-app/web-design';
import { TVariables as MapBranchVariables, useMapBranchApi } from 'apis/branches/use-map-branch-api';
import { TVariables as UnMapBranchVariables, useUnMapBranchApi } from 'apis/branches/use-unmap-branch-api';
import { TVariables as UpdateMapBranchVariables, useUpdateMapBranchApi } from 'apis/branches/use-update-map-branch-api';
import { MapIcon } from 'icons/map-icon';
import { UnMapIcon } from 'icons/unmap-icon';
import { IBranch } from '../index';

export interface IBranchActionsCell {
  row: Record<string, any>;
  branch: IBranch;
}

export const ActionsCell = ({ row, branch }: IBranchActionsCell) => {
  const { isLoading: isMapBranchLoading, mutate: mapBranchApi } = useMapBranchApi();
  const { isLoading: isUpdateMapBranchLoading, mutate: updateMapBranchApi } = useUpdateMapBranchApi();
  const { isLoading: isUnMapBranchLoading, mutate: unMapBranchApi } = useUnMapBranchApi();

  const handleMap = () => {
    // Map for first time
    if (!branch.posBranchId) {
      const variables: MapBranchVariables = {
        body: { branchId: row.state.branchId, vendorBranchId: row.state.vendorBranchId },
      };

      mapBranchApi(variables);
    }

    // Update map if mapped before
    if (branch.posBranchId) {
      const variables: UpdateMapBranchVariables = {
        branchId: row.state.branchId,
        body: {
          vendorBranchId: row.state.vendorBranchId,
        },
      };

      updateMapBranchApi(variables);
    }
  };

  const handleUnMap = () => {
    const variables: UnMapBranchVariables = {
      branchId: branch.kasoBranchId,
    };

    unMapBranchApi(variables);
  };

  return (
    <HStack spacing='xs'>
      <Button
        rightIcon={<MapIcon />}
        colorScheme='red'
        variant='solid'
        size='sm'
        px='md'
        isDisabled={!row.state.branchId}
        isLoading={isMapBranchLoading || isUpdateMapBranchLoading}
        onClick={handleMap}>
        Map
      </Button>

      <IconButtonWithTooltip
        label='Un-map'
        aria-label='Un-map Icon'
        colorScheme='blue'
        Icon={<UnMapIcon />}
        size='sm'
        isDisabled={!branch.posBranchId}
        isLoading={isUnMapBranchLoading}
        onClick={handleUnMap}
      />
    </HStack>
  );
};
