import { Icon, IconProps } from '@elkaso-app/web-design';

export const DimensionsLogo = (props: IconProps) => {
  return (
    <Icon width='482px' height='107px' viewBox='0 0 482 107' fill='none' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M64.2799 2.64938C61.4817 1.03853 58.335 0.129483 55.1089 0V25.6599C55.1039 29.1612 56.0206 32.6021 57.7669 35.6368C59.5133 38.6715 62.0277 41.193 65.0575 42.9479L101.151 63.8319C103.941 65.446 107.083 66.3554 110.304 66.4813V40.8241C110.303 37.3237 109.383 33.8847 107.638 30.8506C105.892 27.8165 103.381 25.2935 100.355 23.5334L64.2799 2.64938Z'
        fill='url(#paint0_linear_36_965)'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M46.1685 2.64938C48.9266 1.0519 52.0293 0.143085 55.2134 0V25.6599C55.2163 29.1607 54.2986 32.6008 52.5525 35.6352C50.8063 38.6695 48.2931 41.1914 45.2648 42.9479L9.17095 63.8319C6.37139 65.4409 3.22592 66.3526 0 66.4813V40.8241C0.000737582 37.3214 0.921914 33.8804 2.67125 30.8457C4.42058 27.8111 6.93664 25.2895 9.96737 23.5334L46.1685 2.64938Z'
        fill='url(#paint1_linear_36_965)'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M64.2798 103.602C61.4859 105.224 58.3368 106.134 55.1089 106.255V80.5919C55.1087 77.092 56.0274 73.6533 57.7733 70.6198C59.5192 67.5864 62.031 65.0646 65.0575 63.3066L101.151 42.4226C103.941 40.8075 107.083 39.8972 110.303 39.7705V65.4304C110.306 68.9313 109.389 72.3713 107.643 75.4057C105.896 78.44 103.383 80.9619 100.355 82.7184L64.2798 103.602Z'
        fill='url(#paint2_linear_36_965)'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M101.049 42.4226L82.5386 53.1274L101.049 63.8322C103.831 65.4449 106.965 66.3544 110.177 66.4816V40.8244V39.7705C106.966 39.9085 103.835 40.8182 101.049 42.4226Z'
        fill='url(#paint3_linear_36_965)'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M46.1685 103.602C48.9545 105.226 52.0982 106.137 55.3207 106.255V80.5919C55.3187 77.0924 54.399 73.6545 52.6533 70.6215C50.9077 67.5884 48.397 65.0662 45.3721 63.3066L9.17095 42.4226C6.37329 40.8098 3.22655 39.8989 7.98934e-06 39.7678V65.4304C-0.00312705 68.9335 0.916439 72.3757 2.66618 75.4106C4.41592 78.4454 6.93411 80.9659 9.96738 82.7184L46.1685 103.602Z'
        fill='url(#paint4_linear_36_965)'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.1924 42.4226L27.6818 53.1274L9.1924 63.8322C6.39057 65.4527 3.2345 66.3623 0 66.4816L0 40.8244V39.7705C3.23297 39.9015 6.3865 40.8113 9.1924 42.4226Z'
        fill='url(#paint5_linear_36_965)'
      />
      <path
        d='M150.639 42.422C152.712 42.3813 154.765 42.8328 156.629 43.7394C158.493 44.6461 160.115 45.9821 161.363 47.6376V29.0651H170.891V76.2392H161.591V72.308C160.313 73.9621 158.667 75.295 156.784 76.2005C154.9 77.106 152.831 77.5591 150.741 77.5237C141.737 77.5237 135.336 70.2673 135.336 59.9594C135.336 49.6569 141.632 42.422 150.637 42.422H150.639ZM144.804 59.9594C144.694 61.5218 145.014 63.0845 145.732 64.4768C146.449 65.8691 147.536 67.0374 148.872 67.8539C151.594 69.5165 155.013 69.5165 157.735 67.8539C159.071 67.0376 160.157 65.8697 160.874 64.478C161.592 63.0862 161.913 61.524 161.803 59.9621C161.913 58.4002 161.592 56.838 160.874 55.4463C160.157 54.0545 159.071 52.8866 157.735 52.0703C156.4 51.2554 154.867 50.8244 153.303 50.8244C151.74 50.8244 150.207 51.2554 148.872 52.0703C147.537 52.8864 146.451 54.0538 145.733 55.4451C145.016 56.8363 144.695 58.398 144.804 59.9594ZM173.848 33.967C173.822 32.8682 174.124 31.7865 174.715 30.86C175.307 29.9334 176.161 29.204 177.168 28.7648C178.174 28.3268 179.288 28.1992 180.368 28.3984C181.447 28.5977 182.442 29.1146 183.226 29.883C184.829 31.4544 185.325 33.8437 184.478 35.9273C184.065 36.944 183.359 37.8146 182.449 38.4279C181.539 39.0412 180.467 39.3694 179.37 39.3704C178.643 39.4153 177.916 39.3073 177.234 39.0532C176.552 38.7991 175.931 38.4047 175.412 37.8955C174.892 37.3859 174.484 36.7732 174.215 36.0966C173.945 35.4201 173.821 34.6947 173.848 33.967ZM184.111 43.6823V76.2392H174.604V43.685H184.111V43.6823Z'
        fill='#414042'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M187.846 76.2393V43.6851H196.977V47.5734C197.981 45.9608 199.387 44.6376 201.058 43.7343C202.729 42.8309 204.606 42.3787 206.505 42.4221C208.617 42.3256 210.714 42.8145 212.566 43.8347C214.417 44.8549 215.951 46.3668 216.998 48.2036C218.167 46.383 219.785 44.8946 221.697 43.8819C223.608 42.8692 225.749 42.3664 227.912 42.4221C235.254 42.4221 240.502 47.9515 240.502 55.7763V76.2393H230.848V57.6695C230.922 56.8172 230.823 55.9587 230.555 55.1461C230.288 54.3334 229.858 53.5835 229.293 52.9419C228.728 52.3008 228.039 51.7814 227.267 51.4154C226.496 51.0493 225.657 50.8443 224.804 50.8127C223.941 50.8301 223.091 51.0248 222.307 51.3846C221.523 51.7444 220.821 52.2617 220.245 52.9043C219.668 53.5477 219.23 54.3025 218.956 55.1221C218.683 55.9417 218.58 56.8087 218.655 57.6695V76.2393H209.17V57.6695C209.17 53.4621 206.883 50.8127 203.335 50.8127C199.787 50.8127 197.269 53.5479 197.269 57.6695V76.2393H187.846Z'
        fill='#414042'
      />
      <path
        d='M260.9 42.4226C263.222 42.2934 265.546 42.6542 267.719 43.4817C269.893 44.3091 271.868 45.5846 273.517 47.2252C275.168 48.8688 276.456 50.8404 277.297 53.0127C278.139 55.185 278.515 57.5097 278.402 59.8366C278.427 60.7913 278.384 61.7459 278.276 62.6952H252.842C253.045 64.7502 253.999 66.6583 255.52 68.0552C257.041 69.452 259.023 70.2397 261.088 70.2679C262.697 70.3223 264.285 69.8814 265.636 69.0049C266.988 68.1283 268.038 66.8582 268.644 65.366L277.206 66.5861C275.611 72.8958 268.813 77.5859 260.836 77.5859C258.468 77.699 256.102 77.3161 253.891 76.4618C251.679 75.6076 249.671 74.3007 247.994 72.625C246.314 70.9469 245.003 68.9375 244.142 66.7246C243.282 64.5118 242.893 62.1441 242.998 59.7723C242.998 49.5287 250.426 42.4226 260.9 42.4226ZM268.77 56.9137C268.57 54.9318 267.641 53.0947 266.164 51.7581C264.687 50.4216 262.766 49.6807 260.774 49.6789C258.782 49.6807 256.861 50.4216 255.384 51.7581C253.907 53.0947 252.978 54.9318 252.777 56.9137H268.77Z'
        fill='#414042'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M290.931 43.6825V48.0803C292.044 46.3469 293.575 44.9206 295.382 43.932C297.19 42.9435 299.216 42.4244 301.276 42.4222C308.581 42.4222 313.512 48.0803 313.512 55.9024V76.2394H304.006V57.5462C304.006 53.6123 301.719 50.8155 297.983 50.8155C297.066 50.8177 296.158 51.0053 295.315 51.3671C294.472 51.7288 293.71 52.2572 293.076 52.9205C292.441 53.5847 291.946 54.37 291.62 55.2297C291.295 56.0894 291.146 57.0058 291.183 57.9243V76.242H281.677V43.6879H290.931V43.6825ZM331.581 77.6499C322.831 77.6499 316.492 72.4959 316.556 65.3683H324.614C324.614 68.605 327.593 70.8977 331.707 70.8977C335.211 70.8977 337.751 69.2566 337.751 67.0899C337.751 64.3574 334.058 63.6012 330.135 63.2016C322.895 62.3167 316.87 61.0966 316.87 53.2316C316.87 46.9218 322.892 42.2961 330.824 42.2961C339.513 42.2961 345.809 46.9862 345.809 53.548H337.416C337.416 50.8771 334.56 49.0242 330.763 49.0242C327.845 49.0242 325.496 50.5607 325.432 52.7247C325.37 54.8941 328.285 55.5243 331.959 56.0928C338.569 56.9777 346.879 58.3212 346.879 65.9341C346.86 72.748 340.647 77.6499 331.581 77.6499Z'
        fill='#414042'
      />
      <path
        d='M350.051 33.9677C350.025 32.8692 350.327 31.7877 350.917 30.8612C351.508 29.9347 352.361 29.2051 353.368 28.7655C354.374 28.328 355.488 28.2008 356.567 28.4C357.645 28.5992 358.64 29.1158 359.423 29.8837C361.03 31.4551 361.526 33.8443 360.678 35.9279C360.266 36.9451 359.559 37.816 358.648 38.4293C357.738 39.0427 356.665 39.3706 355.567 39.371C354.842 39.4156 354.115 39.3073 353.433 39.0533C352.752 38.7992 352.131 38.405 351.612 37.8962C351.092 37.3866 350.684 36.7738 350.415 36.0973C350.146 35.4207 350.021 34.6953 350.049 33.9677H350.051ZM360.332 43.683V76.2398H350.826V43.6857H360.332V43.683ZM400.395 59.9601C400.395 70.3297 393.029 77.5243 382.305 77.5243C371.579 77.5243 364.299 70.3297 364.299 59.9601C364.299 49.5932 371.646 42.4227 382.305 42.4227C392.964 42.4227 400.395 49.5288 400.395 59.9601ZM390.825 59.9601C390.934 58.4024 390.614 56.8445 389.898 55.4566C389.183 54.0686 388.1 52.9039 386.767 52.0897C385.436 51.277 383.907 50.8471 382.347 50.8471C380.787 50.8471 379.258 51.277 377.926 52.0897C376.593 52.9041 375.51 54.0693 374.795 55.4577C374.079 56.8462 373.759 58.4047 373.869 59.9628C373.759 61.5209 374.079 63.0793 374.795 64.4678C375.51 65.8562 376.593 67.0214 377.926 67.8358C379.258 68.6485 380.787 69.0784 382.347 69.0784C383.907 69.0784 385.436 68.6485 386.767 67.8358C388.1 67.0214 389.184 65.8562 389.899 64.4678C390.615 63.0793 390.935 61.5209 390.825 59.9628V59.9601Z'
        fill='#414042'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M412.862 43.6825V48.0803C413.975 46.3469 415.505 44.9206 417.313 43.932C419.12 42.9435 421.147 42.4244 423.207 42.4222C430.49 42.4222 435.44 48.0803 435.44 55.9024V76.2394H426.02V57.5462C426.02 53.6123 423.733 50.8155 419.976 50.8155C419.059 50.8202 418.153 51.0098 417.312 51.3728C416.471 51.7359 415.711 52.2651 415.079 52.9285C413.803 54.2693 413.122 56.0713 413.197 57.9243V76.242H403.669V43.6879H412.862V43.6825ZM453.49 77.6499C444.737 77.6499 438.379 72.4959 438.444 65.3683H446.502C446.502 68.605 449.502 70.8977 453.616 70.8977C457.099 70.8977 459.639 69.2566 459.639 67.0899C459.639 64.3574 455.965 63.6012 452.023 63.2016C444.802 62.3167 438.776 61.0966 438.776 53.2316C438.776 46.9218 444.802 42.2961 452.734 42.2961C461.422 42.2961 467.697 46.9862 467.697 53.548H459.304C459.304 50.8771 456.448 49.0242 452.651 49.0242C449.733 49.0242 447.384 50.5607 447.32 52.7247C447.258 54.8941 450.173 55.5243 453.847 56.0928C460.457 56.9777 468.767 58.3212 468.767 65.9341C468.788 72.748 462.556 77.6499 453.49 77.6499ZM470.507 71.8657C470.477 70.762 470.777 69.6744 471.368 68.742C471.96 67.8096 472.816 67.0747 473.827 66.6313C474.836 66.1884 475.955 66.0572 477.04 66.2545C478.124 66.4519 479.125 66.9687 479.914 67.7388C481.528 69.3156 482.03 71.7156 481.18 73.8072C480.767 74.8297 480.058 75.7056 479.144 76.3228C478.23 76.94 477.153 77.2704 476.05 77.2718C475.324 77.3126 474.597 77.2019 473.916 76.9466C473.235 76.6912 472.615 76.297 472.095 75.7889C471.573 75.2802 471.163 74.6688 470.891 73.9935C470.618 73.3183 470.488 72.5937 470.51 71.8657H470.507Z'
        fill='#414042'
      />
      <defs>
        <linearGradient
          id='paint0_linear_36_965'
          x1='55.1089'
          y1='33.2513'
          x2='110.324'
          y2='33.2513'
          gradientUnits='userSpaceOnUse'>
          <stop stopColor='#8F74FF' />
          <stop offset='1' stopColor='#5439C4' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_36_965'
          x1='55.2134'
          y1='33.2513'
          x2='0'
          y2='33.2513'
          gradientUnits='userSpaceOnUse'>
          <stop stopColor='#6D51E1' />
          <stop offset='1' stopColor='#4EA3FF' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_36_965'
          x1='55.1089'
          y1='73.0231'
          x2='110.324'
          y2='73.0231'
          gradientUnits='userSpaceOnUse'>
          <stop stopColor='#6D51E1' />
          <stop offset='1' stopColor='#4EA3FF' />
        </linearGradient>
        <linearGradient
          id='paint3_linear_36_965'
          x1='96.3685'
          y1='66.4816'
          x2='96.3685'
          y2='39.7705'
          gradientUnits='userSpaceOnUse'>
          <stop stopColor='#6D51E1' />
          <stop offset='1' stopColor='#4EA3FF' />
        </linearGradient>
        <linearGradient
          id='paint4_linear_36_965'
          x1='55.2155'
          y1='73.0218'
          x2='0'
          y2='73.0218'
          gradientUnits='userSpaceOnUse'>
          <stop stopColor='#8F74FF' />
          <stop offset='1' stopColor='#5439C4' />
        </linearGradient>
        <linearGradient
          id='paint5_linear_36_965'
          x1='16.2857'
          y1='43.5563'
          x2='-0.908482'
          y2='65.6304'
          gradientUnits='userSpaceOnUse'>
          <stop stopColor='#6D51E1' />
          <stop offset='1' stopColor='#4EA3FF' />
        </linearGradient>
      </defs>
    </Icon>
  );
};
