import { FullPageSpinner } from '@elkaso-app/web-design';
import { useGetMeApi } from 'apis/auth/use-get-me-api';
import { PreLoginRoute } from 'components/pre-login-route';
import { ProtectedRoute } from 'components/protected-route';
import { useRouteRedirectCorrection } from 'hooks/use-route-redirect-correction';
import { useSetLangHtmlTags } from 'hooks/use-set-lang-html-tags';
import { useSyncLogout } from 'hooks/use-sync-logout';
import BranchesPage from 'pages/branches';
import ConfirmAccountPage from 'pages/confirm-account';
import ItemsPage from 'pages/items';
import LoginPage from 'pages/login';
import NotificationsPage from 'pages/notifications';
import SelectBrandsPage from 'pages/select-brands';
import SelectPOSPage from 'pages/select-pos';
import SuppliersPage from 'pages/suppliers';
import { Navigate, RouteObject, useRoutes } from 'react-router-dom';

const HomeRoute = () => {
  // This is very important so we can force user got to the correct route.
  useRouteRedirectCorrection();
  return <FullPageSpinner />;
};

const Routes = () => {
  const { isLoading } = useGetMeApi();

  // Is Loading
  if (isLoading) return <FullPageSpinner />;

  // Routes
  const routes: RouteObject[] = [
    {
      path: '/',
      element: <HomeRoute />,
    },
    {
      path: '/login',
      element: <PreLoginRoute component={<LoginPage />} />,
    },
    {
      path: '/select-pos',
      element: <ProtectedRoute component={<SelectPOSPage />} />,
    },
    {
      path: '/select-brands',
      element: <ProtectedRoute component={<SelectBrandsPage />} />,
    },
    {
      path: '/confirm-account',
      element: <ProtectedRoute component={<ConfirmAccountPage />} />,
    },
    {
      path: '/app',
      children: [
        {
          path: '/app',
          element: <Navigate to='/app/branches' />,
        },
        {
          path: 'branches',
          element: <ProtectedRoute component={<BranchesPage />} />,
        },
        {
          path: 'suppliers',
          element: <ProtectedRoute component={<SuppliersPage />} />,
        },
        {
          path: 'items',
          element: <ProtectedRoute component={<ItemsPage />} />,
        },
        {
          path: 'notifications',
          element: <ProtectedRoute component={<NotificationsPage />} />,
        },
      ],
    },
    {
      path: '/404',
      element: <>Not found</>,
    },
    {
      path: '*',
      element: <Navigate to='/404' />,
    },
  ];

  return useRoutes(routes);
};

function App() {
  useSyncLogout();
  useSetLangHtmlTags();

  return <Routes />;
}

export default App;
