import { Text, TextProps } from '@elkaso-app/web-design';

interface IBaseTableTitle extends TextProps {
  title: string;
}

export const BaseTableTitle = ({ title, ...rest }: IBaseTableTitle) => {
  return (
    <Text fontWeight='bold' fontSize='xl' {...rest}>
      {title}
    </Text>
  );
};
