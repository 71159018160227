import { useCustomToast } from '@elkaso-app/web-design';
import { useQueryWrapper } from 'apis/config/use-api-wrapper';
import http from 'apis/config/vendor-http-service';
import type { AxiosError } from 'axios';
import fileDownload from 'js-file-download';
import { UseQueryOptions, UseQueryResult } from 'react-query';

const endpoint = 'csv-export-pos-products';
export const downloadCSVItemsEndpointIdentifier = endpoint;

type TData = Record<string, any>;
type TError = AxiosError;
type TQueryKey = [typeof endpoint];

type Options = Omit<UseQueryOptions<unknown, TError, TData, TQueryKey>, 'queryKey' | 'queryFn'>;
type ReturnType = UseQueryResult<TData, TError>;

export function useDownloadCSVItemsApi(options?: Options): ReturnType {
  const toast = useCustomToast();

  const queryFn = async () => {
    try {
      await http
        .get(endpoint, {
          responseType: 'blob',
        })
        .then((response) => {
          fileDownload(response.data, `items-list.csv`);
        });
    } catch (error: any) {
      if (!error.response.status || !error.response.data.message) throw error;

      toast({
        description: 'Something went wrong! Try again later',
        status: 'error',
      });

      throw error;
    }
  };

  return useQueryWrapper([endpoint], queryFn, {
    enabled: false,
    ...options,
  });
}
