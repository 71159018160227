import { Button, HStack, IconButtonWithTooltip } from '@elkaso-app/web-design';
import { TVariables as MapSupplierVariables, useMapSupplierApi } from 'apis/suppliers/use-map-supplier-api';
import { TVariables as UnMapSupplierVariables, useUnMapSupplierApi } from 'apis/suppliers/use-unmap-supplier-api';
import {
  TVariables as UpdateMapSupplierVariables,
  useUpdateMapSupplierApi,
} from 'apis/suppliers/use-update-map-supplier-api';
import { MapIcon } from 'icons/map-icon';
import { UnMapIcon } from 'icons/unmap-icon';
import { ISupplier } from '../index';

export interface ISuppliersActionsCell {
  row: Record<string, any>;
  supplier: ISupplier;
}

export const ActionsCell = ({ row, supplier }: ISuppliersActionsCell) => {
  const { isLoading: isMapSupplierLoading, mutate: mapSupplierApi } = useMapSupplierApi();
  const { isLoading: isUpdateMapSupplierLoading, mutate: updateMapSupplierApi } = useUpdateMapSupplierApi();
  const { isLoading: isUnMapSupplierLoading, mutate: unMapSupplierApi } = useUnMapSupplierApi();

  const handleMap = () => {
    // Map for first time
    if (!supplier.posSupplierId) {
      const variables: MapSupplierVariables = {
        body: { supplierId: row.state.supplierId, vendorSupplierId: row.state.vendorSupplierId },
      };

      mapSupplierApi(variables);
    }

    // Update map if mapped before
    if (supplier.posSupplierId) {
      const variables: UpdateMapSupplierVariables = {
        supplierId: row.state.supplierId,
        body: {
          vendorSupplierId: row.state.vendorSupplierId,
        },
      };

      updateMapSupplierApi(variables);
    }
  };

  const handleUnMap = () => {
    const variables: UnMapSupplierVariables = {
      supplierId: supplier.kasoSupplierId,
    };

    unMapSupplierApi(variables);
  };

  return (
    <HStack spacing='xs'>
      <Button
        rightIcon={<MapIcon />}
        colorScheme='red'
        variant='solid'
        size='sm'
        px='md'
        isDisabled={!row.state.supplierId}
        isLoading={isMapSupplierLoading || isUpdateMapSupplierLoading}
        onClick={handleMap}>
        Map
      </Button>

      <IconButtonWithTooltip
        label='Un-map'
        aria-label='Un-map Icon'
        colorScheme='blue'
        Icon={<UnMapIcon />}
        size='sm'
        isDisabled={!supplier.posSupplierId}
        isLoading={isUnMapSupplierLoading}
        onClick={handleUnMap}
      />
    </HStack>
  );
};
