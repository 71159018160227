import {
  Box,
  Button,
  ButtonProps,
  Center,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@elkaso-app/web-design';
import { useDownloadCSVItemsApi } from 'apis/csv-downloads/use-download-csv-items-api';
import { DownloadArrowIcon } from 'icons/download-arrow-icon';
import { useState } from 'react';

const DownloadButton = ({ children, ...props }: ButtonProps) => {
  return (
    <Button variant={'solid'} boxSize='140px' size='sm' {...props}>
      <Center flexDir='column'>
        <DownloadArrowIcon mb='sm' />
        <Text textAlign={'center'} whiteSpace={'break-spaces'}>
          {children}
        </Text>
      </Center>
    </Button>
  );
};

interface IDownloadCSVButton extends ButtonProps {
  templateType: 'branches' | 'suppliers' | 'items';
}

export const DownloadCSVButton = ({ templateType, ...props }: IDownloadCSVButton) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { refetch: downloadCSVItemsList } = useDownloadCSVItemsApi();
  const [isFileDownloading, setIsFileDownloading] = useState(false);

  const onDownload = () => {
    setIsFileDownloading(true);

    switch (templateType) {
      case 'branches':
        // Under construction.
        break;

      case 'suppliers':
        // Under construction.
        break;

      case 'items':
        downloadCSVItemsList().then(() => setIsFileDownloading(false));
        break;

      default:
        break;
    }
  };

  const onDownloadTemplate = () => {
    // Under construction.
  };

  return (
    <Box>
      <Button colorScheme='blue' variant='solid' onClick={onOpen} {...props}>
        Download Files
      </Button>

      <Modal size='lg' variant='secondary' isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text color='red.500' fontWeight={'semibold'} fontSize='lg'>
              Download Bulk Mapping Files
            </Text>
            <Text color='gray.500' fontWeight={'normal'} fontSize='sm'>
              Download
              <Text as='span' color='red.500' mx='xs'>
                .csv
              </Text>
              files.
            </Text>
          </ModalHeader>
          <ModalCloseButton />

          <ModalBody py='2xl'>
            <HStack spacing='xl' justify='center'>
              <DownloadButton colorScheme={'red'} onClick={onDownload} isLoading={isFileDownloading}>
                Download Items Lists
              </DownloadButton>
              <DownloadButton colorScheme={'blue'} onClick={onDownloadTemplate} disabled>
                Download Items Template
              </DownloadButton>
            </HStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};
