import { Icon } from '@elkaso-app/web-design';
import { BranchesIcon } from 'layout/components/sidebar/icons/branches-icon';
import { ItemsIcon } from 'layout/components/sidebar/icons/items-icon';
import { NotificationsIcon } from 'layout/components/sidebar/icons/notifications-icon';
import { SuppliersIcon } from 'layout/components/sidebar/icons/suppliers-icon';

export type TSidebarItem = {
  name: string;
  path: string;
  icon: typeof Icon;
};

export const sidebarItems: TSidebarItem[] = [
  {
    name: 'Branches',
    path: '/app/branches',
    icon: BranchesIcon,
  },
  {
    name: 'Suppliers',
    path: '/app/suppliers',
    icon: SuppliersIcon,
  },
  {
    name: 'Items',
    path: '/app/items',
    icon: ItemsIcon,
  },
  {
    name: 'Notifications',
    path: '/app/notifications',
    icon: NotificationsIcon,
  },
];
