import { Flex, Icon, IconProps, Text } from '@elkaso-app/web-design';
import { TSidebarItem } from 'layout/components/sidebar/utils/sidebar-items';
import { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

interface IItemIcon extends IconProps {
  icon: typeof Icon;
}

const ItemIcon = ({ icon: Icon, ...rest }: IItemIcon) => {
  return <Icon {...rest} />;
};

interface ISidebarItem {
  item: TSidebarItem;
}

export const SidebarItem = ({ item }: ISidebarItem) => {
  const [isActive, setIsActive] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.startsWith(item.path)) {
      return setIsActive(true);
    }

    setIsActive(false);
  }, [location.pathname]);

  return (
    <NavLink to={item.path}>
      <Flex
        p={'sm'}
        align={'center'}
        borderRadius='base'
        bgColor={isActive ? 'red.500' : 'white'}
        _hover={{ bgColor: isActive ? 'red.500' : 'gray.100' }}
        _active={{ bgColor: isActive ? 'red.500' : 'gray.200' }}>
        <ItemIcon icon={item.icon} marginEnd={'md'} fill={isActive ? 'white' : 'gray.500'} />
        <Text color={isActive ? 'white' : 'gray.500'} fontSize='md' fontWeight='medium'>
          {item.name}
        </Text>
      </Flex>
    </NavLink>
  );
};
