import { useCustomToast } from '@elkaso-app/web-design';
import http, { getAccessToken } from 'apis/config/http-service';
import { useMutationWrapper } from 'apis/config/use-api-wrapper';
import vendorHttp from 'apis/config/vendor-http-service';
import type { AxiosError } from 'axios';
import axios from 'axios';
import { useLogout } from 'hooks/use-logout';
import { useRouteRedirectCorrection } from 'hooks/use-route-redirect-correction';
import type { UseMutationOptions, UseMutationResult } from 'react-query';
import { LocalStorageKeys } from 'utils/constants';

const endpoint = process.env.REACT_APP_BASE_URL + '/auth/signin';
export const loginEndpointIdentifier = endpoint;

interface BodyType {
  phoneNumber: string;
  pin: string;
}

type TData = Record<string, any>;
type TError = AxiosError;

export type TVariables = {
  body: BodyType;
};

type Options = Omit<UseMutationOptions<TData, TError, TVariables, unknown>, 'mutationFn'>;
type ReturnType = UseMutationResult<TData, TError, TVariables>;

export function useLoginApi(options?: Options): ReturnType {
  const toast = useCustomToast();
  const { onLogout } = useLogout();
  const { routeRedirectCorrection } = useRouteRedirectCorrection({ onInitiateCall: false });

  const mutationFn = async ({ body }: TVariables) => {
    try {
      const { data } = await axios.post(endpoint, body);

      // [1] - Add access token to local storage
      localStorage.setItem(LocalStorageKeys.access_token, data.token);

      // [2] - Update default configs for axios instance
      http.defaults.headers.common['Authorization'] = getAccessToken();
      vendorHttp.defaults.headers.common['Authorization'] = getAccessToken();

      // [3] - Redirect user to the correct next step
      routeRedirectCorrection();

      return data;
    } catch (error: any) {
      onLogout();

      if (!error.response.status || !error.response.data.message) throw error;

      toast({
        description: error.response.data.message,
        status: 'error',
      });

      throw error;
    }
  };

  return useMutationWrapper(mutationFn, { mutationKey: endpoint, ...options });
}
