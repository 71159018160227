<svg fill='none' xmlns='http://www.w3.org/2000/svg'></svg>;

import { Icon, IconProps } from '@elkaso-app/web-design';

export const FileIcon = (props: IconProps) => {
  return (
    <Icon width='87px' height='106px' viewBox='0 0 87 106' fill='none' stroke='#71BD4D' {...props}>
      <path
        d='M29.0625 57.9375H57.9375M29.0625 77.1875H43.5M5 14.625V91.625C5 94.1777 6.01406 96.6259 7.8191 98.4309C9.62413 100.236 12.0723 101.25 14.625 101.25H72.375C74.9277 101.25 77.3759 100.236 79.1809 98.4309C80.9859 96.6259 82 94.1777 82 91.625V35.5209C81.9998 34.2386 81.7435 32.9693 81.246 31.7876C80.7485 30.6058 80.0198 29.5353 79.1029 28.639L57.7354 7.74312C55.9372 5.98492 53.5224 5.00031 51.0075 5H14.625C12.0723 5 9.62413 6.01406 7.8191 7.8191C6.01406 9.62413 5 12.0723 5 14.625V14.625Z'
        stroke='inherit'
        strokeWidth='9'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M53.125 5V24.25C53.125 26.8027 54.1391 29.2509 55.9441 31.0559C57.7491 32.8609 60.1973 33.875 62.75 33.875H82'
        stroke='inherit'
        strokeWidth='9'
        strokeLinejoin='round'
      />
    </Icon>
  );
};
