import axios from 'axios';
import { LocalStorageKeys } from 'utils/constants';

export const getAccessToken = () => {
  const accessToken = localStorage.getItem(LocalStorageKeys.access_token);
  if (accessToken) return 'Bearer ' + accessToken;
  return '';
};

export const getLanguage = () => {
  const language = localStorage.getItem(LocalStorageKeys.language);
  if (language) return language;
  return 'en';
};

// Create API instance
const http = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL + '/',
});

http.defaults.headers.common['Authorization'] = getAccessToken();
http.defaults.headers.common['Accept-Language'] = getLanguage();
http.defaults.headers.post['Content-Type'] = 'application/json';

// Add a request interceptor
http.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
http.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },

  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response) {
      // [1] - Access denied
      if (error.response.status === 403) {
        window.location.href = '/access-denied';
      }

      // [2] - Unauthorized
      if (error.response.status === 401 || error.response.status === 564) {
        localStorage.removeItem(LocalStorageKeys.access_token);
        localStorage.removeItem(LocalStorageKeys.has_mapped_branch);
        window.location.href = '/';
      }

      // [3] - Expired session
      if (error.response.data.code === 107) {
        window.location.href = '/expired-session';
      }
    }

    return Promise.reject(error);
  }
);

export default http;
