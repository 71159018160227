import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Initial State
type SliceState = {
  data: { _id: string; name: string }[];
};

const initialState: SliceState = {
  data: [],
};

const listOfBrandsSlice = createSlice({
  name: 'listOfBrands',
  initialState,
  reducers: {
    setListOfBrandsAction(state, action: PayloadAction<SliceState['data']>) {
      state.data = action.payload;
    },
  },
});

export const { setListOfBrandsAction } = listOfBrandsSlice.actions;
export default listOfBrandsSlice.reducer;
