import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  Heading,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  PasswordInput,
  Text,
  VStack,
} from '@elkaso-app/web-design';

import { yupResolver } from '@hookform/resolvers/yup';
import { TVariables as LoginVariables, useLoginApi } from 'apis/auth/use-login-api';
import { Layout, Section } from 'layout';
import { useForm } from 'react-hook-form';
import { MdPhoneIphone } from 'react-icons/md';
import * as Yup from 'yup';

const LoginPage = () => {
  const { isLoading, mutate: loginApi } = useLoginApi();

  // Form
  type TInputs = {
    phoneNumber: string;
    pin: string;
  };

  const defaultValues: TInputs = {
    phoneNumber: '',
    pin: '',
  };

  const formSchema = Yup.object().shape({
    phoneNumber: Yup.string()
      .required('Phone number is required.')
      .notOneOf(
        ['+971', '+966', '+973', '+98', '+964', '+962', '+965', '+961', '+968', '+970', '+974', '+963', '+967', '+32'],
        'Phone number is required.'
      )
      .matches(/^\+[1-9]{1}[0-9]{3,14}$/, 'Please enter correct phone number starts with country code e.g. +971')
      .test('Check country code', 'Country code is not acceptable.', function (): any {
        const phoneNumber = this.parent['phoneNumber'];
        if (!phoneNumber) return;

        if (
          phoneNumber.startsWith('+971') ||
          phoneNumber.startsWith('+966') ||
          phoneNumber.startsWith('+973') ||
          phoneNumber.startsWith('+98') ||
          phoneNumber.startsWith('+964') ||
          phoneNumber.startsWith('+962') ||
          phoneNumber.startsWith('+965') ||
          phoneNumber.startsWith('+961') ||
          phoneNumber.startsWith('+968') ||
          phoneNumber.startsWith('+970') ||
          phoneNumber.startsWith('+974') ||
          phoneNumber.startsWith('+963') ||
          phoneNumber.startsWith('+967') ||
          phoneNumber.startsWith('+32')
        ) {
          return true;
        }

        return false;
      }),
    pin: Yup.string().required('Pin is required.').min(4, 'Invalid pin.').max(4, 'Invalid pin.'),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<TInputs>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: { ...defaultValues },
    resolver: yupResolver(formSchema),
  });

  const onSubmit = (data: TInputs) => {
    const variables: LoginVariables = {
      body: { phoneNumber: data.phoneNumber, pin: data.pin },
    };

    loginApi(variables);
  };

  return (
    <Layout type='pre_login_page_layout'>
      <Section name='content'>
        <Box w='100%' maxW='500px'>
          <Heading as='h3' mb='sm'>
            Login
          </Heading>

          <Text fontSize='md' color='gray.500' mb='lg'>
            Please enter your account information
          </Text>

          <VStack as='form' id='loginForm' spacing='md' onSubmit={handleSubmit(onSubmit)} noValidate mb='lg'>
            <FormControl isRequired isInvalid={errors.phoneNumber ? true : false}>
              <InputGroup size='lg'>
                <InputLeftElement pointerEvents='none'>
                  <Icon as={MdPhoneIphone} />
                </InputLeftElement>

                <Input type='tel' placeholder='Phone number' {...register('phoneNumber')} />
              </InputGroup>
              <FormErrorMessage>{errors.phoneNumber?.message}</FormErrorMessage>
            </FormControl>

            <PasswordInput
              isRequired
              isInvalid={errors.pin ? true : false}
              placeholder='Pin'
              {...register('pin')}
              errorMessage={errors.pin?.message}
            />
          </VStack>

          <HStack align='center' justify='flex-end'>
            <Button
              variant='solid'
              colorScheme='red'
              size='lg'
              type='submit'
              form='loginForm'
              w='150px'
              isLoading={isLoading}>
              Login
            </Button>
          </HStack>
        </Box>
      </Section>
    </Layout>
  );
};

export default LoginPage;
