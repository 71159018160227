import { ChakraSelect, FormControl } from '@elkaso-app/web-design';
import { useGetAllKasoBranchesApi } from 'apis/branches/use-get-all-kaso-branches-api';
import { usePageParams } from 'hooks/use-page-params';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

type TOption = {
  value: string;
  label: string;
};

type TInputs = {
  options: TOption[] | undefined;
};

const defaultValues: TInputs = {
  options: undefined,
};

export const SelectKasoBranch = () => {
  const [options, setOptions] = useState<TOption[]>([]);
  const { getPageParams, setPageParams } = usePageParams();
  const params = getPageParams();

  const { isLoading: isAllKasoBranchesLoading, data: allKasoBranchesData } = useGetAllKasoBranchesApi();

  useEffect(() => {
    if (isAllKasoBranchesLoading) return;

    const _options: TOption[] = [];

    allKasoBranchesData?.data?.map((branch: any) => {
      _options.push({ value: branch?.id, label: branch?.nameEn });
    });

    setOptions(_options);
  }, [allKasoBranchesData]);

  const { control, watch, setValue } = useForm<TInputs>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: { ...defaultValues },
  });

  // Watch select input and update value
  useEffect(() => {
    const subscription = watch((value: any) => {
      const params = getPageParams();

      // When cleared
      if (!value?.options) {
        delete params.branchId;
        setPageParams(params);
      }

      // When selected
      if (value?.options && value?.options?.value) {
        params.branchId = value?.options?.value;
        setPageParams(params);
      }
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  useMemo(() => {
    const branch = options.filter((option) => option.value === params.branchId);
    return setValue('options', branch);
  }, [options]);

  return (
    <Controller
      control={control}
      name='options'
      render={({ field: { onChange, onBlur, value, name } }) => (
        <FormControl variant='outline' width='230px'>
          <ChakraSelect
            options={options}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            name={name}
            placeholder='All branches...'
            isLoading={isAllKasoBranchesLoading}
            isClearable
          />
        </FormControl>
      )}
    />
  );
};
