import { Button, HStack, IconButtonWithTooltip } from '@elkaso-app/web-design';
import { TVariables as MapItemVariables, useMapItemApi } from 'apis/items/use-map-item-api';
import { TVariables as UnMapItemVariables, useUnMapItemApi } from 'apis/items/use-unmap-item-api';
import { TVariables as UpdateMapItemVariables, useUpdateMapItemApi } from 'apis/items/use-update-map-item-api';
import { usePageParams } from 'hooks/use-page-params';
import { MapIcon } from 'icons/map-icon';
import { UnMapIcon } from 'icons/unmap-icon';
import { IItem } from '../index';

export interface IItemsActionsCell {
  row: Record<string, any>;
  item: IItem;
}

export const ActionsCell = ({ row, item }: IItemsActionsCell) => {
  const { isLoading: isMapItemLoading, mutate: mapItemApi } = useMapItemApi();
  const { isLoading: isUpdateMapItemLoading, mutate: updateMapItemApi } = useUpdateMapItemApi();
  const { isLoading: isUnMapItemLoading, mutate: unMapItemApi } = useUnMapItemApi();
  const { getPageParams } = usePageParams();
  const params = getPageParams();

  const handleMap = () => {
    // Map for first time
    if (!item.posItemId) {
      const variables: MapItemVariables = {
        body: { itemId: row.state.itemId, vendorItemId: row.state.vendorItemId },
      };

      if (params?.branchId) variables.body.branchId = params.branchId as string;

      mapItemApi(variables);
    }

    // Update map if mapped before
    if (item.posItemId) {
      const variables: UpdateMapItemVariables = {
        itemId: row.state.itemId,
        body: {
          vendorItemId: row.state.vendorItemId,
        },
      };

      if (params?.branchId) variables.body.branchId = params.branchId as string;

      updateMapItemApi(variables);
    }
  };

  const handleUnMap = () => {
    const variables: UnMapItemVariables = {
      itemId: item.kasoItemId,
      branchId: item.kasoBranchId,
    };

    unMapItemApi(variables);
  };

  return (
    <HStack spacing='xs'>
      <Button
        rightIcon={<MapIcon />}
        colorScheme='red'
        variant='solid'
        size='sm'
        px='md'
        isDisabled={!row.state.itemId}
        isLoading={isMapItemLoading || isUpdateMapItemLoading}
        onClick={handleMap}>
        Map
      </Button>

      <IconButtonWithTooltip
        label='Un-map'
        aria-label='Un-map Icon'
        colorScheme='blue'
        Icon={<UnMapIcon />}
        size='sm'
        isDisabled={!item.posItemId}
        isLoading={isUnMapItemLoading}
        onClick={handleUnMap}
      />
    </HStack>
  );
};
