import { LocalStorageKeys } from 'utils/constants';

export const useLogout = () => {
  const onLogout = () => {
    localStorage.removeItem(LocalStorageKeys.access_token);
    localStorage.removeItem(LocalStorageKeys.vendor);
    window.location.href = '/login';
  };

  return { onLogout };
};
