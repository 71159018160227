import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LocalStorageKeys } from 'utils/constants';

export const useSetLangHtmlTags = () => {
  const currentLanguage = localStorage.getItem(LocalStorageKeys.language);
  const [_document, setDocument] = useState<Document | null>(null);
  const htmlElement = _document?.querySelector('html');
  const { i18n } = useTranslation();

  // Set default value for [lang, dir] attribute in html tag
  const setHTMLTags = () => {
    if (currentLanguage === 'ar') {
      htmlElement?.setAttribute('lang', 'ar');
      htmlElement?.setAttribute('dir', 'rtl');
      i18n.changeLanguage('ar');
    } else {
      htmlElement?.setAttribute('lang', 'en');
      htmlElement?.setAttribute('dir', 'ltr');
      i18n.changeLanguage('en');
    }
  };

  // Get document object & set default language
  useEffect(() => {
    setDocument(document);
    setHTMLTags();
  }, [_document]);
};
