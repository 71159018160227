import { createSlice } from '@reduxjs/toolkit';

// Initial State
type SliceState = {
  isOpen: boolean;
};

const initialState: SliceState = {
  isOpen: true,
};

const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    toggleSidebarAction(state) {
      state.isOpen = !state.isOpen;
    },
  },
});

export const { toggleSidebarAction } = sidebarSlice.actions;
export default sidebarSlice.reducer;
