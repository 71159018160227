import { BasePagination } from '@elkaso-app/web-design';
import { FilterOptions } from 'components/filter-options';
import { useSetPaginationParamsV2 } from 'hooks/use-set-pagination-params-v2';
import { Layout, Section } from 'layout';
import { ActionsCell, IItemsActionsCell } from 'pages/items/components/actions-cell';
import { ISelectItemsCell, SelectItemsCell } from 'pages/items/components/select-items-cell';
import React from 'react';

import { useGetAllPosItemsApi } from 'apis/items/use-get-all-pos-items-api';
import { useGetKasoItemsApi } from 'apis/items/use-get-kaso-items-api';
import { BaseTable, BaseTableContainer } from 'components/base-table';
import {
  BaseTableSpinner,
  BaseTableTitle,
  ClearFilterButton,
  CustomTableCell,
  TableCell,
  TableHeader,
} from 'components/base-table/components';
import { NameFilterButton } from 'components/base-table/filters';
import { DownloadCSVButton } from 'components/download-csv-button';
import { UploadCSVButton } from 'components/upload-csv-button';
import { useVendorName } from 'hooks/use-vendor-name';
import { CellProps } from 'react-table';
import { getValidValue } from 'utils/get-valid-value';
import { SelectKasoBranch } from './components/select-kaso-branch';

export interface IItem {
  kasoBranchId: string;
  kasoItemId: string;
  supplierName: string;
  kasoItemName: string;
  kasoUnit: string;
  code: string;
  posItemsList: { id: string; name: string }[];
  posItemName: string;
  kasoBranchName: string;
  posItemId: string;
  posUnit: string;
  sku: string;
}

const ItemsPage = () => {
  const setPaginationParams = useSetPaginationParamsV2();
  const { VendorName } = useVendorName();

  const { isLoading: isLoadingItems, isFetching: isFetchingItems, data: kasoItemsApiData } = useGetKasoItemsApi();
  const { pagesCount, total, data: kasoItemsData } = kasoItemsApiData ?? {};

  const {
    isLoading: isLoadingPosItems,
    isFetching: isFetchingPosItems,
    data: posItemsApiData,
  } = useGetAllPosItemsApi();

  const isLoading = isLoadingItems || isLoadingPosItems;
  const isFetching = isFetchingItems || isFetchingPosItems;

  const mergedItemsData = React.useMemo(() => {
    const items: IItem[] = [];

    kasoItemsData?.map((kasoItem: any) => {
      const posItem = posItemsApiData?.data?.find(
        (posItem: any) => getValidValue([posItem?._id, posItem?.id]) === kasoItem?.posMappedItem?.vendorItemId
      );

      items.push({
        kasoBranchId: kasoItem?.branchId,
        kasoItemId: kasoItem?.id,
        supplierName: kasoItem?.supplier?.name,
        kasoItemName: getValidValue([kasoItem?.nameEn, kasoItem?.nameAr]),
        kasoUnit: kasoItem?.defaultUnit,
        code: kasoItem?.code,
        posItemsList: posItemsApiData?.data?.map((posItem: any) => {
          return {
            id: getValidValue([posItem?._id, posItem?.id]),
            name: getValidValue([posItem?.name]),
          };
        }),
        posItemName: getValidValue([posItem?.name]),
        kasoBranchName: kasoItem?.branch?.nameEn,
        posItemId: getValidValue([posItem?._id, posItem?.id]),
        posUnit: getValidValue([posItem?.measurement_unit, posItem?.storage_unit]),
        sku: getValidValue([posItem?.item_code, posItem?.sku]),
      });
    });

    return items;
  }, [kasoItemsApiData, posItemsApiData]);

  const columns = React.useMemo(
    () => [
      {
        Header: <TableHeader name='Supplier' />,
        accessor: 'supplier_name',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['supplier_name']>) => (
          <TableCell value={value} fontWeight='semibold' fontSize='sm' color='red.500' />
        ),
      },
      {
        Header: <TableHeader name={'Kaso Item Name'} filterButtonComponent={<NameFilterButton />} />,
        accessor: 'kaso_item_name',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['kaso_item_name']>) => (
          <TableCell value={value} fontSize='sm' color='gray.500' />
        ),
      },
      {
        Header: <TableHeader name={'Kaso Unit'} />,
        accessor: 'kaso_unit',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['kaso_unit']>) => (
          <TableCell value={value} fontSize='sm' color='gray.500' />
        ),
      },
      {
        Header: <TableHeader name='Code' />,
        accessor: 'code',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['code']>) => (
          <TableCell value={value} fontSize='sm' color='gray.500' />
        ),
      },
      {
        Header: <TableHeader name={`Select ${VendorName} Item`} />,
        accessor: 'select_pos_item',
        Cell: ({ row, value }: CellProps<any, IColumnsAccessorData['select_pos_item']>) => (
          <CustomTableCell<ISelectItemsCell>
            as={SelectItemsCell}
            row={row}
            id={value?.kasoItemId}
            options={value?.posItemsList}
            defaultValue={{ id: value?.posItemId, name: value?.posItemName }}
          />
        ),
      },
      {
        Header: <TableHeader name='Actions' />,
        accessor: 'actions',
        Cell: ({ row, value }: CellProps<any, IColumnsAccessorData['actions']>) => (
          <CustomTableCell<IItemsActionsCell> as={ActionsCell} row={row} item={value} />
        ),
      },
      {
        Header: <TableHeader name={`${VendorName} Item Name`} />,
        accessor: 'pos_item_name',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['pos_item_name']>) => (
          <TableCell value={value} fontWeight='semibold' fontSize='sm' color='blue.800' />
        ),
      },
      {
        Header: <TableHeader name='Branch' />,
        accessor: 'kaso_branch_name',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['kaso_branch_name']>) => (
          <TableCell value={value} fontSize='sm' color='gray.500' />
        ),
      },
      {
        Header: <TableHeader name={`${VendorName} Unit`} />,
        accessor: 'pos_unit',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['pos_unit']>) => (
          <TableCell value={value} fontSize='sm' color='gray.500' />
        ),
      },
      {
        Header: <TableHeader name='SKU' />,
        accessor: 'sku',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['sku']>) => (
          <TableCell value={value} fontSize='sm' color='gray.500' />
        ),
      },
    ],
    []
  );

  interface IColumnsAccessorData {
    id: string;
    supplier_name: string;
    kaso_item_name: string;
    kaso_unit: string;
    code: string;
    select_pos_item: IItem;
    actions: IItem;
    pos_item_name: string;
    kaso_branch_name: string;
    pos_unit: string;
    sku: string;
  }

  const columnsAccessorData = React.useMemo(
    () =>
      mergedItemsData?.map((item): IColumnsAccessorData => {
        return {
          id: item?.kasoItemId,
          supplier_name: item?.supplierName || '--',
          kaso_item_name: item?.kasoItemName || '--',
          kaso_unit: item?.kasoUnit || '--',
          code: item?.code || '--',
          select_pos_item: item,
          actions: item,
          pos_item_name: item?.posItemName || '--',
          kaso_branch_name: item?.kasoBranchName || '--',
          pos_unit: item?.posUnit || '--',
          sku: item?.sku || '--',
        };
      }),
    [kasoItemsApiData, posItemsApiData]
  );

  return (
    <Layout type='sidebar_page_layout'>
      <Section name='content'>
        <BaseTableContainer isLoading={isLoading}>
          <BaseTableContainer.Header>
            <BaseTableContainer.Header.Left>
              <BaseTableTitle title='Items' />
              <BaseTableSpinner isFetching={isFetching} />
            </BaseTableContainer.Header.Left>

            <BaseTableContainer.Header.Right>
              <SelectKasoBranch />
              <DownloadCSVButton templateType='items' />
              <UploadCSVButton templateType='items' />
              <ClearFilterButton />
            </BaseTableContainer.Header.Right>
          </BaseTableContainer.Header>

          <BaseTableContainer.TableWrapper>
            <FilterOptions
              options={[
                { name: 'All', value: '' },
                { name: 'Mapped', value: 'true' },
                { name: 'Unmapped', value: 'false' },
              ]}
              paramName='mapped'
              radioInputName='mapped'
            />
            <BaseTable columns={columns} data={columnsAccessorData} />
          </BaseTableContainer.TableWrapper>

          <BaseTableContainer.Footer>
            <BaseTableContainer.Footer.Left />
            <BaseTableContainer.Footer.Right>
              <BasePagination>
                <BasePagination.Pages pagesCount={pagesCount} setSearchParams={setPaginationParams} />
                <BasePagination.Limit total={total} setSearchParams={setPaginationParams} />
              </BasePagination>
            </BaseTableContainer.Footer.Right>
          </BaseTableContainer.Footer>
        </BaseTableContainer>
      </Section>
    </Layout>
  );
};

export default ItemsPage;
