import { IconButton, Tooltip } from '@elkaso-app/web-design';
import { useToggleFullScreen } from 'hooks/use-toggle-full-screen';
import { FullscreenIcon } from 'layout/components/header/icons/fullscreen-icon';

export const FullScreenButton = () => {
  const toggleFullScreen = useToggleFullScreen();

  return (
    <Tooltip label='Fullscreen'>
      <IconButton
        size={'lg'}
        aria-label='fullscreen'
        icon={<FullscreenIcon />}
        onClick={toggleFullScreen}
        isRound
        variant={'ghost'}
      />
    </Tooltip>
  );
};
