import { Box, Flex, VStack } from '@elkaso-app/web-design';
import { Section } from 'layout/index';
import { DimensionsLogo } from 'layout/logos/dimensions-logo';
import { LayoutProps } from 'layout/types';

export const DimensionsLoginLayout = ({ sections }: LayoutProps) => {
  return (
    <Box as='main'>
      <Box as='section' minH={'100vh'}>
        <Flex h='100vh'>
          <VStack display={['none', 'flex']} justify='center' align='start' px='100px' bgColor='white'>
            <DimensionsLogo width='300px' />
          </VStack>

          {/* Start Form */}
          <Flex p={['md', '0px']} justify='center' align='center' flexGrow={1} bgColor='gray.50' pos='relative'>
            <Box w={['100%', '500px']}>
              <Section sections={sections} name='form' />
            </Box>
          </Flex>
          {/* End Form */}
        </Flex>
      </Box>
    </Box>
  );
};
