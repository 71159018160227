import { ChakraSelect, formatOptionsArray, FormControl } from '@elkaso-app/web-design';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

type TOption = {
  value: number;
  label: string;
};

type TInputs = {
  options: TOption[] | undefined;
};

const defaultValues: TInputs = {
  options: undefined,
};

export interface ISelectSuppliersCell {
  row: Record<string, any>;
  id: string;
  options: { id: string; name: string }[];
  defaultValue: { id: string; name: string };
}

export const SelectSuppliersCell = ({ row, id, defaultValue, options }: ISelectSuppliersCell) => {
  const { control, watch } = useForm<TInputs>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: { ...defaultValues },
  });

  // Watch select input and update row state value
  useEffect(() => {
    const subscription = watch((value: any) => {
      // When cleared
      if (!value?.options) {
        row.setState({});
      }

      // When selected
      if (value?.options && value?.options?.value) {
        row.setState({ supplierId: id, vendorSupplierId: value?.options?.value });
      }
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  const getDefaultValue = () => {
    if (!defaultValue.id || !defaultValue.name) return undefined;
    return formatOptionsArray([defaultValue], 'id', 'name');
  };

  return (
    <Controller
      control={control}
      name='options'
      render={({ field: { onChange, onBlur, value, name } }) => (
        <FormControl variant='outline'>
          <ChakraSelect
            options={formatOptionsArray(options, 'id', 'name')}
            id={id}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            defaultValue={getDefaultValue}
            name={name}
            placeholder='Search...'
            isClearable
          />
        </FormControl>
      )}
    />
  );
};
