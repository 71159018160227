import { Icon, IconProps } from '@elkaso-app/web-design';

export const MapIcon = (props: IconProps) => {
  return (
    <Icon width='12px' height='12px' viewBox='0 0 12 12' fill='white' {...props}>
      <path
        d='M0.949367 11.0512C1.2506 11.3528 1.60847 11.592 2.00242 11.7549C2.39637 11.9179 2.81863 12.0013 3.24494 12.0006C3.67134 12.0013 4.09369 11.9178 4.48774 11.7549C4.88179 11.592 5.23978 11.3528 5.54115 11.0512L7.37761 9.21406L6.45938 8.29584L4.62293 10.1329C4.25698 10.4973 3.76163 10.7018 3.24526 10.7018C2.72889 10.7018 2.23354 10.4973 1.86759 10.1329C1.50297 9.76716 1.29822 9.27175 1.29822 8.75528C1.29822 8.2388 1.50297 7.74339 1.86759 7.37761L3.7047 5.54115L2.78647 4.62293L0.949367 6.45938C0.341419 7.0688 0 7.89447 0 8.75528C0 9.61608 0.341419 10.4418 0.949367 11.0512ZM11.0512 5.54115C11.6588 4.93157 12 4.10596 12 3.24526C12 2.38456 11.6588 1.55895 11.0512 0.949367C10.4417 0.341419 9.61608 0 8.75527 0C7.89447 0 7.0688 0.341419 6.45938 0.949367L4.62293 2.78647L5.54115 3.7047L7.37761 1.86759C7.74356 1.50328 8.2389 1.29875 8.75527 1.29875C9.27165 1.29875 9.76699 1.50328 10.1329 1.86759C10.4976 2.23337 10.7023 2.72879 10.7023 3.24526C10.7023 3.76174 10.4976 4.25715 10.1329 4.62293L8.29584 6.45938L9.21406 7.37761L11.0512 5.54115Z'
        fill='inherit'
      />
      <path d='M3.70403 9.21478L2.78516 8.29655L8.29647 2.78589L9.2147 3.70477L3.70403 9.21478Z' fill='inherit' />
    </Icon>
  );
};
