import { Icon, IconProps } from '@elkaso-app/web-design';

export const ItemsIcon = (props: IconProps) => {
  return (
    <Icon width='24px' height='24px' viewBox='0 0 24 24' fill='#757575' {...props}>
      <path
        d='M21 3H3C1.89543 3 1 3.89543 1 5C1 6.10457 1.89543 7 3 7H21C22.1046 7 23 6.10457 23 5C23 3.89543 22.1046 3 21 3Z'
        fill='inherit'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M21 19C21 20.1046 20.1046 21 19 21H5C3.89543 21 3 20.1046 3 19V9H21V19ZM9 12.6923C9 12.1825 9.44772 11.7692 10 11.7692H14C14.5523 11.7692 15 12.1825 15 12.6923C15 13.2021 14.5523 13.6154 14 13.6154H10C9.44772 13.6154 9 13.2021 9 12.6923Z'
        fill='inherit'
      />
      <path d='M21 7C21 5.89543 20.1046 5 19 5H5C3.89543 5 3 5.89543 3 7H21Z' fill='inherit' />
    </Icon>
  );
};
