import { BasePagination } from '@elkaso-app/web-design';
import { FilterOptions } from 'components/filter-options';
import { useSetPaginationParamsV2 } from 'hooks/use-set-pagination-params-v2';
import { Layout, Section } from 'layout';
import { ActionsCell, INotificationsActionsCell } from 'pages/notifications/components/actions-cell';
import React from 'react';

import { useGetFailedJobsApi } from 'apis/notifications/use-get-failed-jobs-api';
import { BaseTable, BaseTableContainer } from 'components/base-table';
import {
  BaseTableSpinner,
  BaseTableTitle,
  ClearFilterButton,
  CustomTableCell,
  TableCell,
  TableHeader,
} from 'components/base-table/components';
import { CellProps } from 'react-table';

const NotificationsPage = () => {
  const setPaginationParams = useSetPaginationParamsV2();
  const { isFetching, isLoading, data: failedJobApiData } = useGetFailedJobsApi();
  const { data: failedJobsData, pagesCount, total } = failedJobApiData ?? {};

  const columns = React.useMemo(
    () => [
      {
        Header: <TableHeader name='Order Code' />,
        accessor: 'order_code',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['order_code']>) => (
          <TableCell value={value} fontWeight='semibold' fontSize='sm' color='gray.600' />
        ),
      },
      {
        Header: <TableHeader name='Fail Reason' />,
        accessor: 'fail_reason',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['fail_reason']>) => (
          <TableCell value={value} fontSize='sm' color='gray.600' whiteSpace={'break-spaces'} />
        ),
      },
      {
        Header: <TableHeader name='Actions' />,
        accessor: 'actions',
        Cell: () => <CustomTableCell<INotificationsActionsCell> as={ActionsCell} isDisabled />,
      },
    ],
    []
  );

  interface IColumnsAccessorData {
    id: string;
    order_code: string;
    fail_reason: string;
    actions: any;
  }

  const columnsAccessorData = React.useMemo(
    () =>
      failedJobsData?.map((item: any): IColumnsAccessorData => {
        return {
          id: item?.orderCode,
          order_code: item?.orderCode,
          fail_reason: item?.failedReason,
          actions: item,
        };
      }),
    [failedJobApiData]
  );

  return (
    <Layout type='sidebar_page_layout'>
      <Section name='content'>
        <BaseTableContainer isLoading={isLoading}>
          <BaseTableContainer.Header>
            <BaseTableContainer.Header.Left>
              <BaseTableTitle title='Notifications' />
              <BaseTableSpinner isFetching={isFetching} />
            </BaseTableContainer.Header.Left>

            <BaseTableContainer.Header.Right>
              <ClearFilterButton />
            </BaseTableContainer.Header.Right>
          </BaseTableContainer.Header>

          <BaseTableContainer.TableWrapper>
            <FilterOptions
              options={[{ name: 'Failed Jobs', value: '' }]}
              paramName='notifications'
              radioInputName='notifications'
            />
            <BaseTable columns={columns} data={columnsAccessorData} />
          </BaseTableContainer.TableWrapper>

          <BaseTableContainer.Footer>
            <BaseTableContainer.Footer.Left />
            <BaseTableContainer.Footer.Right>
              <BasePagination>
                <BasePagination.Pages pagesCount={pagesCount} setSearchParams={setPaginationParams} />
                <BasePagination.Limit total={total} setSearchParams={setPaginationParams} />
              </BasePagination>
            </BaseTableContainer.Footer.Right>
          </BaseTableContainer.Footer>
        </BaseTableContainer>
      </Section>
    </Layout>
  );
};

export default NotificationsPage;
