import { Box, HStack, IconButton, Tooltip } from '@elkaso-app/web-design';
import { MenuIcon } from 'layout/components/header/icons/menu-icon';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { toggleSidebarAction } from 'redux/sidebar/reducer';

export function HeaderLeft(): JSX.Element {
  const isSidebarOpen = useAppSelector((state) => state.sidebar.isOpen);
  const dispatch = useAppDispatch();

  const handleToggleSidebar = () => {
    dispatch(toggleSidebarAction());
  };

  return (
    <Box me='auto'>
      <HStack spacing='0.5rem'>
        <Box onClick={handleToggleSidebar}>
          <Tooltip label={isSidebarOpen ? 'Close' : 'Open'}>
            <IconButton aria-label='sidebar' icon={<MenuIcon />} isRound variant={'ghost'} size={'lg'} />
          </Tooltip>
        </Box>
      </HStack>
    </Box>
  );
}
