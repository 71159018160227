import { Text, VStack } from '@elkaso-app/web-design';
import { DataRow, IDataRow } from 'pages/confirm-account/components/data-row';

interface IDataSection {
  title: string;
  data: IDataRow[];
}

export const DataSection = ({ title, data = [] }: IDataSection) => {
  return (
    <VStack spacing='md' align='flex-start'>
      <Text fontWeight='semibold' fontSize='lg'>
        {title}
      </Text>

      {data?.map(({ icon, text }, index) => (
        <DataRow key={index} icon={icon} text={text} />
      ))}
    </VStack>
  );
};
