import {
  closestCenter,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { Table, Tbody, Td, Tfoot, Th, Thead, Tr } from '@elkaso-app/web-design';
import { DraggableTableRow, EmptyTableState, StaticTableRow } from 'components/base-table/components';
import { useMemo, useState } from 'react';
import { useRowState, useTable } from 'react-table';

interface IBaseTable {
  columns: any;
  data: any;
  handleDataReorder?: any;
  initialHiddenColumns?: string[];
  renderHeader?: boolean;
  renderFooter?: boolean;
}

export const BaseTable = ({
  columns,
  data,
  handleDataReorder,
  initialHiddenColumns = [],
  renderHeader = true,
  renderFooter,
}: IBaseTable) => {
  const [activeId, setActiveId] = useState<any>();
  const items = useMemo(() => data?.map(({ id }: { id: any }) => id), [data]);

  const { getTableProps, getTableBodyProps, rows, prepareRow, headerGroups, footerGroups } = useTable(
    {
      columns,
      data,
      initialState: {
        hiddenColumns: [...initialHiddenColumns],
      },
    },
    useRowState
  );

  const sensors = useSensors(useSensor(MouseSensor, {}), useSensor(TouchSensor, {}), useSensor(KeyboardSensor, {}));

  const handleDragStart = (event: any) => {
    setActiveId(event.active.id);
  };

  const handleDragEnd = (event: any) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      handleDataReorder((data: any) => {
        const oldIndex = items.indexOf(active.id);
        const newIndex = items.indexOf(over.id);
        return arrayMove(data, oldIndex, newIndex);
      });
    }

    setActiveId(null);
  };

  const handleDragCancel = () => {
    setActiveId(null);
  };

  const selectedRow = useMemo(() => {
    if (!activeId) return null;

    const row: any = rows.find(({ original }: any) => original.id === activeId);

    prepareRow(row);
    return row;
  }, [activeId, rows, prepareRow]);

  return (
    <DndContext
      sensors={sensors}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
      onDragCancel={handleDragCancel}
      collisionDetection={closestCenter}
      modifiers={[restrictToVerticalAxis]}>
      <Table {...getTableProps()}>
        {/* Header */}
        {!!renderHeader && (
          <Thead>
            {headerGroups?.map((headerGroup, i) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={i}>
                {headerGroup.headers?.map((column) => (
                  <Th {...column.getHeaderProps()} key={i}>
                    {column.render('Header')}
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
        )}
        {/* /Header */}

        {/* Body */}
        <Tbody {...getTableBodyProps()}>
          {!rows.length && <EmptyTableState />}

          {!!rows.length && (
            <SortableContext items={items} strategy={verticalListSortingStrategy}>
              {rows?.map((row, i) => {
                prepareRow(row);
                return <DraggableTableRow key={i} row={row} enableDragging={handleDataReorder !== undefined} />;
              })}
            </SortableContext>
          )}
        </Tbody>
        {/* /Body */}

        {/* Footer */}
        {!!renderFooter && (
          <Tfoot>
            {footerGroups?.map((footerGroup, i) => (
              <Tr {...footerGroup.getFooterGroupProps()} key={i}>
                {footerGroup.headers?.map((column) => (
                  <Td {...column.getFooterProps()} key={i}>
                    {column.render('Footer')}
                  </Td>
                ))}
              </Tr>
            ))}
          </Tfoot>
        )}
        {/* /Footer */}
      </Table>

      <DragOverlay>
        {activeId && (
          <Table>
            <Tbody>
              <StaticTableRow row={selectedRow} />
            </Tbody>
          </Table>
        )}
      </DragOverlay>
    </DndContext>
  );
};
