import { Icon, IconProps } from '@elkaso-app/web-design';

export const EmailIcon = (props: IconProps) => {
  return (
    <Icon width='16px' height='11px' viewBox='0 0 16 11' fill='#757575' {...props}>
      <path
        d='M14.8571 0H1.14286C0.839753 0 0.549062 0.115892 0.334735 0.322183C0.120408 0.528472 0 0.808262 0 1.1V9.9C0 10.1917 0.120408 10.4715 0.334735 10.6778C0.549062 10.8841 0.839753 11 1.14286 11H14.8571C15.1602 11 15.4509 10.8841 15.6653 10.6778C15.8796 10.4715 16 10.1917 16 9.9V1.1C16 0.808262 15.8796 0.528472 15.6653 0.322183C15.4509 0.115892 15.1602 0 14.8571 0ZM13.6 1.1L8 4.829L2.4 1.1H13.6ZM1.14286 9.9V1.6005L7.67429 5.951C7.76994 6.01487 7.88358 6.0491 8 6.0491C8.11642 6.0491 8.23006 6.01487 8.32571 5.951L14.8571 1.6005V9.9H1.14286Z'
        fill='inherit'
      />
    </Icon>
  );
};
