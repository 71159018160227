import {
  Button,
  Divider,
  Flex,
  FullPageSpinner,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  VStack,
  WhiteCard,
} from '@elkaso-app/web-design';
import { useGetOwnerApi } from 'apis/vendors/use-get-owner-api';
import { useLogout } from 'hooks/use-logout';
import { useVendorName } from 'hooks/use-vendor-name';
import { Layout, Section } from 'layout';
import { DataSection } from 'pages/confirm-account/components/data-section';
import { ArrowsIcon } from 'pages/confirm-account/icons/arrows-icon';
import { EmailIcon } from 'pages/confirm-account/icons/email-icon';
import { PhoneIcon } from 'pages/confirm-account/icons/phone-icon';
import { UserIcon } from 'pages/confirm-account/icons/user-icon';
import { useNavigate } from 'react-router';

const ConfirmAccountPage = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isLoading: isGetOwnerLoading, data: ownerApiData } = useGetOwnerApi();
  const { kasoUser, vendorUser } = ownerApiData ?? {};
  const { onLogout } = useLogout();
  const navigate = useNavigate();
  const { VendorName } = useVendorName();

  const onCancel = () => {
    onLogout();
    onClose();
  };

  const onConfirm = () => {
    navigate('/app');
    onClose();
  };

  if (isGetOwnerLoading) return <FullPageSpinner />;
  return (
    <Layout type='pre_login_page_layout'>
      <Section name='content'>
        <>
          <WhiteCard w='100%' maxW='800px'>
            <VStack spacing='lg' p='xl' align='flex-start'>
              <Flex align='center' justify='space-between' w='100%'>
                <Text fontSize='xl' fontWeight='bold'>
                  Confirm KASO &amp; {VendorName}
                </Text>

                <Button variant={'link'} onClick={onCancel}>
                  Skip
                </Button>
              </Flex>

              <VStack spacing='lg' align='flex-start' w='100%'>
                <DataSection
                  title='KASO'
                  data={[
                    { icon: <EmailIcon />, text: kasoUser?.email },
                    { icon: <UserIcon />, text: kasoUser?.name },
                    { icon: <PhoneIcon />, text: kasoUser?.phoneNumber },
                  ]}
                />

                <HStack spacing='lg' w='100%'>
                  <Divider />
                  <ArrowsIcon />
                  <Divider />
                </HStack>

                <DataSection
                  title={VendorName}
                  data={[
                    { icon: <EmailIcon />, text: vendorUser?.email },
                    { icon: <UserIcon />, text: vendorUser?.name },
                    { icon: <PhoneIcon />, text: vendorUser?.phone },
                  ]}
                />
              </VStack>

              <HStack spacing='sm' justify='flex-end' w='100%'>
                <Button variant='ghost' colorScheme='gray' onClick={onCancel}>
                  Cancel
                </Button>
                <Button variant='solid' colorScheme='red' onClick={onOpen}>
                  Continue
                </Button>
              </HStack>
            </VStack>
          </WhiteCard>

          <Modal variant='secondary' isOpen={isOpen} onClose={onCancel} closeOnOverlayClick={false}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>
                <Text fontSize='xl' fontWeight='bold'>
                  Confirm KASO &amp; {VendorName}
                </Text>
              </ModalHeader>

              <ModalBody>
                <Text color='gray.500'>Are you sure you want to continue using previous users?</Text>
              </ModalBody>

              <ModalFooter>
                <HStack spacing='sm' justify='flex-end' w='100%'>
                  <Button variant='ghost' colorScheme='gray' onClick={onClose}>
                    Cancel
                  </Button>

                  <Button variant='solid' colorScheme='red' onClick={onConfirm}>
                    Continue
                  </Button>
                </HStack>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </>
      </Section>
    </Layout>
  );
};

export default ConfirmAccountPage;
