import { BasePagination } from '@elkaso-app/web-design';
import { FilterOptions } from 'components/filter-options';
import { useSetPaginationParamsV2 } from 'hooks/use-set-pagination-params-v2';
import { Layout, Section } from 'layout';
import { ActionsCell, ISuppliersActionsCell } from 'pages/suppliers/components/actions-cell';
import { ISelectSuppliersCell, SelectSuppliersCell } from 'pages/suppliers/components/select-suppliers-cell';
import React from 'react';

import { useGetAllPosSuppliersApi } from 'apis/suppliers/use-get-all-pos-suppliers-api';
import { useGetKasoSuppliersApi } from 'apis/suppliers/use-get-kaso-suppliers-api';
import { BaseTable, BaseTableContainer } from 'components/base-table';
import {
  BaseTableSpinner,
  BaseTableTitle,
  ClearFilterButton,
  CustomTableCell,
  TableCell,
  TableHeader,
} from 'components/base-table/components';
import { NameFilterButton } from 'components/base-table/filters';
import { DownloadCSVButton } from 'components/download-csv-button';
import { UploadCSVButton } from 'components/upload-csv-button';
import { useVendorName } from 'hooks/use-vendor-name';
import { CellProps } from 'react-table';
import { getValidValue } from 'utils/get-valid-value';

export interface ISupplier {
  kasoSupplierId: string;
  kasoSupplierName: string;
  posSuppliersList: { id: string; name: string }[];
  posSupplierName: string;
  posSupplierId: string;
  reference: string;
}

const SuppliersPage = () => {
  const setPaginationParams = useSetPaginationParamsV2();
  const { VendorName } = useVendorName();

  const {
    isLoading: isLoadingSuppliers,
    isFetching: isFetchingSuppliers,
    data: kasoSuppliersApiData,
  } = useGetKasoSuppliersApi();
  const { pagesCount, total, data: kasoSuppliersData } = kasoSuppliersApiData ?? {};

  const {
    isLoading: isLoadingPosSuppliers,
    isFetching: isFetchingPosSuppliers,
    data: posSuppliersApiData,
  } = useGetAllPosSuppliersApi();

  const isLoading = isLoadingSuppliers || isLoadingPosSuppliers;
  const isFetching = isFetchingSuppliers || isFetchingPosSuppliers;

  const mergedSuppliersData = React.useMemo(() => {
    const suppliers: ISupplier[] = [];

    kasoSuppliersData?.map((kasoSupplier: any) => {
      const posSupplier = posSuppliersApiData?.data?.find(
        (posSupplier: any) =>
          getValidValue([posSupplier?._id, posSupplier?.id]) === kasoSupplier?.posMappedSupplier?.vendorSupplierId
      );

      suppliers.push({
        kasoSupplierId: kasoSupplier?.id,
        kasoSupplierName: kasoSupplier?.name,
        posSuppliersList: posSuppliersApiData?.data?.map((posSupplier: any) => {
          return {
            id: getValidValue([posSupplier?._id, posSupplier?.id]),
            name: getValidValue([posSupplier?.name]),
          };
        }),
        posSupplierName: getValidValue([posSupplier?.name]),
        posSupplierId: kasoSupplier?.posMappedSupplier?.vendorSupplierId,
        reference: getValidValue([posSupplier?.reference]),
      });
    });

    return suppliers;
  }, [kasoSuppliersApiData, posSuppliersApiData]);

  const columns = React.useMemo(
    () => [
      {
        Header: <TableHeader name='Kaso Supplier Name' filterButtonComponent={<NameFilterButton />} />,
        accessor: 'kaso_supplier_name',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['kaso_supplier_name']>) => (
          <TableCell value={value} fontWeight='semibold' fontSize='sm' color='red.500' />
        ),
      },
      {
        Header: <TableHeader name={`Select ${VendorName} Supplier`} />,
        accessor: 'select_pos_supplier',
        Cell: ({ row, value }: CellProps<any, IColumnsAccessorData['select_pos_supplier']>) => (
          <CustomTableCell<ISelectSuppliersCell>
            as={SelectSuppliersCell}
            row={row}
            id={value?.kasoSupplierId}
            options={value?.posSuppliersList}
            defaultValue={{ id: value?.posSupplierId, name: value?.posSupplierName }}
          />
        ),
      },
      {
        Header: <TableHeader name={`${VendorName} Supplier Name`} />,
        accessor: 'pos_supplier_name',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['pos_supplier_name']>) => (
          <TableCell value={value} fontWeight='semibold' fontSize='sm' color='blue.800' />
        ),
      },
      {
        Header: <TableHeader name='Reference' />,
        accessor: 'reference',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['reference']>) => (
          <TableCell value={value} fontSize='sm' color='gray.500' />
        ),
      },
      {
        Header: <TableHeader name='Actions' />,
        accessor: 'actions',
        Cell: ({ row, value }: CellProps<any, IColumnsAccessorData['actions']>) => (
          <CustomTableCell<ISuppliersActionsCell> as={ActionsCell} row={row} supplier={value} />
        ),
      },
    ],
    []
  );

  interface IColumnsAccessorData {
    id: string;
    kaso_supplier_name: string;
    select_pos_supplier: ISupplier;
    pos_supplier_name: string;
    reference: string;
    actions: ISupplier;
  }

  const columnsAccessorData = React.useMemo(
    () =>
      mergedSuppliersData?.map((supplier): IColumnsAccessorData => {
        return {
          id: supplier?.kasoSupplierId,
          kaso_supplier_name: supplier?.kasoSupplierName || '--',
          select_pos_supplier: supplier,
          pos_supplier_name: supplier?.posSupplierName || '--',
          reference: supplier?.reference || '--',
          actions: supplier,
        };
      }),
    [kasoSuppliersApiData, posSuppliersApiData]
  );

  return (
    <Layout type='sidebar_page_layout'>
      <Section name='content'>
        <BaseTableContainer isLoading={isLoading}>
          <BaseTableContainer.Header>
            <BaseTableContainer.Header.Left>
              <BaseTableTitle title='Suppliers' />
              <BaseTableSpinner isFetching={isFetching} />
            </BaseTableContainer.Header.Left>

            <BaseTableContainer.Header.Right>
              <DownloadCSVButton templateType='suppliers' disabled />
              <UploadCSVButton templateType='suppliers' />
              <ClearFilterButton />
            </BaseTableContainer.Header.Right>
          </BaseTableContainer.Header>

          <BaseTableContainer.TableWrapper>
            <FilterOptions
              options={[
                { name: 'All', value: '' },
                { name: 'Mapped', value: 'true' },
                { name: 'Unmapped', value: 'false' },
              ]}
              paramName='mapped'
              radioInputName='mapped'
            />
            <BaseTable columns={columns} data={columnsAccessorData} />
          </BaseTableContainer.TableWrapper>

          <BaseTableContainer.Footer>
            <BaseTableContainer.Footer.Left />
            <BaseTableContainer.Footer.Right>
              <BasePagination>
                <BasePagination.Pages pagesCount={pagesCount} setSearchParams={setPaginationParams} />
                <BasePagination.Limit total={total} setSearchParams={setPaginationParams} />
              </BasePagination>
            </BaseTableContainer.Footer.Right>
          </BaseTableContainer.Footer>
        </BaseTableContainer>
      </Section>
    </Layout>
  );
};

export default SuppliersPage;
