import { FeatureFlagsProvider } from 'feature-flags';

// React query
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

// Redux
import { Provider } from 'react-redux';
import store from 'redux/store';

// CASL
import { ability } from 'configs/ability';
import { AbilityContext } from 'configs/can';

// Kaso Theme
import { KasoThemeProvider } from '@elkaso-app/web-design';

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export function Providers({ children }: { children: React.ReactNode }) {
  return (
    <QueryClientProvider client={queryClient}>
      <FeatureFlagsProvider>
        <Provider store={store}>
          <AbilityContext.Provider value={ability}>
            <KasoThemeProvider>{children}</KasoThemeProvider>
          </AbilityContext.Provider>
        </Provider>
      </FeatureFlagsProvider>

      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}
