import { Icon, IconProps } from '@elkaso-app/web-design';

export const ArrowsIcon = (props: IconProps) => {
  return (
    <Icon width='68px' height='55px' viewBox='0 0 68 55' fill='none' {...props}>
      <path
        d='M17.3333 39.25V4M17.3333 4L4 15.75M17.3333 4L30.6667 15.75M50.6667 15.75V51M50.6667 51L64 39.25M50.6667 51L37.3333 39.25'
        stroke='#E2E3ED'
        strokeWidth='7'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </Icon>
  );
};
