import { HStack, Text } from '@elkaso-app/web-design';

export interface IDataRow {
  icon: JSX.Element;
  text: string;
}

export const DataRow = ({ icon, text }: IDataRow) => {
  return (
    <HStack spacing='md'>
      {icon}
      <Text color='gray.500'>{text || '---'}</Text>
    </HStack>
  );
};
