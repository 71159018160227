import { Box, HStack, useRadio, useRadioGroup } from '@elkaso-app/web-design';
import { usePageParams } from 'hooks/use-page-params';

const RadioCard = (props: any) => {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as='label'>
      <input {...input} />
      <Box
        {...checkbox}
        cursor='pointer'
        color='gray.500'
        fontWeight='semibold'
        borderBottomWidth='1px'
        borderColor='white'
        _checked={{
          color: 'red.500',
          borderBottomWidth: '1px',
          borderColor: 'red.500',
        }}>
        {props.children}
      </Box>
    </Box>
  );
};

interface IFilterOptions {
  options: { name: string; value: string }[];
  radioInputName: string;
  paramName: string;
}

export const FilterOptions = ({ options, radioInputName, paramName }: IFilterOptions) => {
  const { getPageParams, setPageParams } = usePageParams();
  const params = getPageParams();

  const onChange = (value: string) => {
    params[paramName] = value;
    setPageParams(params);
  };

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: radioInputName,
    defaultValue: params[paramName]?.toString(),
    onChange,
  });

  const group = getRootProps();

  return (
    <HStack spacing='md' px='md' mb='md' {...group}>
      {options.map(({ name, value }: (typeof options)[0]) => {
        const radio = getRadioProps({ value });

        return (
          <RadioCard key={name} {...radio}>
            {name}
          </RadioCard>
        );
      })}
    </HStack>
  );
};
