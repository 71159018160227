import _capitalize from 'lodash/capitalize';
import _lowerCase from 'lodash/lowerCase';
import { LocalStorageKeys } from 'utils/constants';

export const useVendorName = () => {
  const vendorName = _lowerCase(localStorage.getItem(LocalStorageKeys.vendor) ?? '');
  const VendorName = _capitalize(vendorName);

  const setVendorName = (vendor: string): void => {
    localStorage.setItem(LocalStorageKeys.vendor, vendor);
  };

  return {
    setVendorName,
    vendorName,
    VendorName,
  };
};
