/**
 * IMPORTANT: This hook should be called in ['/', '/login', '/select-pos', '/app']
 */

import { useBoolean } from '@elkaso-app/web-design';
import { getMeEndpointIdentifier } from 'apis/auth/use-get-me-api';
import { useFoodicsLoginApi } from 'apis/vendors/use-foodics-login-api';
import { useIsLoggedIn } from 'hooks/use-is-logged-in';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { LocalStorageKeys } from 'utils/constants';
import { useGetQueryClientData } from './use-get-query-client-data';

interface UseRouteRedirectCorrection {
  onInitiateCall?: boolean;
}

export const useRouteRedirectCorrection = (options?: UseRouteRedirectCorrection) => {
  const { onInitiateCall = true } = options ?? {};
  const [isLoading, setIsLoading] = useBoolean(true);
  const isLoggedIn = useIsLoggedIn();
  const navigate = useNavigate();
  const me = useGetQueryClientData(getMeEndpointIdentifier);
  const { mutateAsync: foodicsLoginApi } = useFoodicsLoginApi();

  const hasPOSIntegrated = me?.vendors?.length;
  const hasManyPOSsIntegrated = me?.vendors?.length > 1;
  const hasBrandIntegrated = me?.brands?.length;
  const hasFoodicsOnlyIntegrated = me?.vendors?.find((item: any) => item.slug === 'foodics');

  // Redirection rules
  const routeRedirectCorrection = async () => {
    // [1] - If user:
    //     > ❌  Has Logged in
    //     > ❌  Has POS
    //     > ❌  Has more than one POS
    //     > ❌  Has brand
    // ======> Redirect to [/login] page
    if (!isLoggedIn) return navigate('/login');

    // [2] - If user:
    //     > ✅  Has Logged in
    //     > ❌  Has POS
    //     > ❌  Has more than one POS
    //     > ❌  Has brand
    // ======> Redirect to [/select-pos] page
    if (!hasPOSIntegrated) return navigate('/select-pos');

    // [3] - If user:
    //     > ✅  Has Logged in
    //     > ✅  Has POS
    //     > ✅  Has more than one POS
    //     > ❌  Has brand
    // ======> Redirect to [/select-pos] page
    if (hasManyPOSsIntegrated) return navigate('/select-pos');

    // [4] - If user:
    //     > ✅  Has Logged in
    //     > ✅  Has POS
    //     > ✅  Has more than one POS (One is selected)
    //     > ❌  Has brand
    // ======> Redirect to [/select-brands] page
    if (!hasBrandIntegrated) return navigate('/select-brands');

    // [5] - If user:
    //     > ✅  Has Logged in
    //     > ✅  Has POS
    //     > ✅  Has more than one POS (One is selected)
    //     > ✅  Has brand
    // ======> Redirect to [/app] page
    if (hasFoodicsOnlyIntegrated) {
      const data = await foodicsLoginApi({});

      if (data?.user) {
        navigate('/app');
      }

      if (!data?.user) {
        localStorage.removeItem(LocalStorageKeys.vendor);
        localStorage.removeItem(LocalStorageKeys.access_token);
        window.location.pathname = '/';
      }

      return;
    }

    return navigate('/app');
  };

  useEffect(() => {
    // Call the hook when initiated
    if (onInitiateCall) {
      routeRedirectCorrection();
    }

    // End loading
    setIsLoading.off();
  }, []);

  return { isLoading, routeRedirectCorrection };
};
