import { Icon, IconProps } from '@elkaso-app/web-design';

export const SuppliersIcon = (props: IconProps) => {
  return (
    <Icon width='22px' height='18px' viewBox='0 0 22 18' fill='#757575' {...props}>
      <path
        d='M21.45 12.375H20.9V8.57461C20.9 8.12812 20.7247 7.69922 20.4153 7.38281L16.9813 3.8707C16.6719 3.5543 16.2525 3.375 15.8159 3.375H14.3V1.6875C14.3 0.755859 13.5609 0 12.65 0H1.65C0.739063 0 0 0.755859 0 1.6875V12.9375C0 13.8691 0.739063 14.625 1.65 14.625H2.2C2.2 16.4883 3.67813 18 5.5 18C7.32188 18 8.8 16.4883 8.8 14.625H13.2C13.2 16.4883 14.6781 18 16.5 18C18.3219 18 19.8 16.4883 19.8 14.625H21.45C21.7525 14.625 22 14.3719 22 14.0625V12.9375C22 12.6281 21.7525 12.375 21.45 12.375ZM5.5 16.3125C4.58906 16.3125 3.85 15.5566 3.85 14.625C3.85 13.6934 4.58906 12.9375 5.5 12.9375C6.41094 12.9375 7.15 13.6934 7.15 14.625C7.15 15.5566 6.41094 16.3125 5.5 16.3125ZM16.5 16.3125C15.5891 16.3125 14.85 15.5566 14.85 14.625C14.85 13.6934 15.5891 12.9375 16.5 12.9375C17.4109 12.9375 18.15 13.6934 18.15 14.625C18.15 15.5566 17.4109 16.3125 16.5 16.3125ZM19.25 9H14.3V5.0625H15.8159L19.25 8.57461V9Z'
        fill='inherit'
      />
    </Icon>
  );
};
