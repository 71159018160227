import { Icon, IconProps } from '@elkaso-app/web-design';

export const BranchesIcon = (props: IconProps) => {
  return (
    <Icon width='22px' height='21px' viewBox='0 0 22 21' fill='#757575' {...props}>
      <path
        d='M21.5447 10.3279L11.5489 0.229981C11.4769 0.157078 11.3914 0.099239 11.2973 0.059776C11.2032 0.0203129 11.1023 0 11.0005 0C10.8986 0 10.7977 0.0203129 10.7036 0.059776C10.6095 0.099239 10.524 0.157078 10.452 0.229981L0.456229 10.3279C0.165019 10.6224 0 11.0222 0 11.4393C0 12.3053 0.696477 13.0095 1.55312 13.0095H2.60633V20.2149C2.60633 20.6492 2.95335 21 3.38289 21H9.44734V15.5045H12.1653V21H18.618C19.0476 21 19.3946 20.6492 19.3946 20.2149V13.0095H20.4478C20.8603 13.0095 21.2559 12.8451 21.5471 12.5482C22.1514 11.9349 22.1514 10.9413 21.5447 10.3279Z'
        fill='inherit'
      />
    </Icon>
  );
};
