import {
  Box,
  BoxProps,
  Flex,
  FlexProps,
  HStack,
  StackProps,
  TableContainer,
  TableContainerProps,
  WhiteCard,
} from '@elkaso-app/web-design';
import { BaseTableContainerSkeleton } from 'components/base-table/components';

/**
 * Base Table Container Header
 */
const HeaderLeft = (props: StackProps) => {
  return <HStack spacing='sm' {...props} />;
};

Header.Left = HeaderLeft;

const HeaderRight = (props: StackProps) => {
  return <HStack spacing='sm' {...props} />;
};

Header.Right = HeaderRight;

function Header(props: FlexProps) {
  return <Flex p='md' align={'center'} justify={'space-between'} {...props} />;
}

BaseTableContainer.Header = Header;

/**
 * Base Table Container Table Wrapper
 */
function TableWrapper(props: TableContainerProps) {
  return <TableContainer flexGrow={1} overflowY='auto' minH='400px' {...props} />;
}

BaseTableContainer.TableWrapper = TableWrapper;

/**
 * Base Table Container Footer
 */
const FooterLeft = (props: BoxProps) => {
  return <Box {...props} />;
};

Footer.Left = FooterLeft;

const FooterRight = (props: BoxProps) => {
  return <Box {...props} />;
};

Footer.Right = FooterRight;

function Footer(props: FlexProps) {
  return (
    <Flex p='md' align={'center'} justify={'space-between'} borderTop='1px solid' borderColor='gray.200' {...props} />
  );
}

BaseTableContainer.Footer = Footer;

/**
 * Base Table Container
 */
interface IBaseTableContainer extends BoxProps {
  isLoading?: boolean;
}

export function BaseTableContainer({ isLoading, ...rest }: IBaseTableContainer) {
  if (isLoading) return <BaseTableContainerSkeleton />;
  return <WhiteCard display='flex' flexDir='column' maxH='100%' {...rest} />;
}
