import { Icon, IconProps } from '@elkaso-app/web-design';

export const DownloadArrowIcon = (props: IconProps) => {
  return (
    <Icon width='40px' height='45px' viewBox='0 0 54 67' fill='none' stroke='white' {...props}>
      <path
        d='M4 63H50M6.55556 29.6522L27 47.6087L47.4444 29.6522M27 4V47.6087'
        stroke='inherit'
        strokeWidth='7.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </Icon>
  );
};
