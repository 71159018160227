import { useIsLoggedIn } from 'hooks/use-is-logged-in';
import { Navigate } from 'react-router-dom';

interface IProtectedRoute {
  component: JSX.Element;
}

export const PreLoginRoute = ({ component }: IProtectedRoute) => {
  const isLoggedIn = useIsLoggedIn();

  if (isLoggedIn) {
    return <Navigate to='/' />;
  }

  return component;
};
