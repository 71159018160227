import { Box, HStack } from '@elkaso-app/web-design';
import { FullScreenButton } from 'layout/components/header/components/fullscreen-button';
import { ProfileButton } from 'layout/components/header/components/profile-button';

export function HeaderRight(): JSX.Element {
  return (
    <Box ms={'auto'}>
      <HStack spacing='md'>
        <FullScreenButton />
        <ProfileButton />
      </HStack>
    </Box>
  );
}
