import { useCustomToast } from '@elkaso-app/web-design';
import { useMutationWrapper } from 'apis/config/use-api-wrapper';
import http from 'apis/config/vendor-http-service';
import { useGetAllPosSuppliersApi } from 'apis/suppliers/use-get-all-pos-suppliers-api';
import { useGetKasoSuppliersApi } from 'apis/suppliers/use-get-kaso-suppliers-api';
import type { AxiosError } from 'axios';
import type { UseMutationOptions, UseMutationResult } from 'react-query';

const endpoint = 'map-supplier';
export const mapSupplierEndpointIdentifier = endpoint;

interface BodyType {
  supplierId: string;
  vendorSupplierId: string;
}

type TData = Record<string, any>;
type TError = AxiosError;

export type TVariables = {
  body: BodyType;
};

type Options = Omit<UseMutationOptions<TData, TError, TVariables, unknown>, 'mutationFn'>;
type ReturnType = UseMutationResult<TData, TError, TVariables>;

export function useMapSupplierApi(options?: Options): ReturnType {
  const { refetch: refetchKasoSuppliers } = useGetKasoSuppliersApi({ enabled: false });
  const { refetch: refetchPosSuppliers } = useGetAllPosSuppliersApi({ enabled: false });
  const toast = useCustomToast();

  const mutationFn = async ({ body }: TVariables) => {
    try {
      const { data } = await http.post(endpoint, body);

      // Refetch suppliers list
      refetchKasoSuppliers();
      refetchPosSuppliers();

      toast({
        description: 'Supplier have been mapped successfully',
        status: 'success',
      });

      return data;
    } catch (error: any) {
      if (!error.response.status || !error.response.data.message) throw error;

      toast({
        description: error.response.data.message,
        status: 'error',
      });

      throw error;
    }
  };

  return useMutationWrapper(mutationFn, { mutationKey: endpoint, ...options });
}
