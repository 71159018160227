import { IconButtonWithTooltip } from '@elkaso-app/web-design';
import { ClearFilter } from 'components/base-table/icons/clear-filter';
import { usePageParams } from 'hooks/use-page-params';

export const ClearFilterButton = () => {
  const { getPageParams, setPageParams } = usePageParams();
  const params = getPageParams();

  const handleClearFilters = () => {
    const newParams: Record<string, any> = {};
    newParams.page = params?.page;
    newParams.limit = params?.limit;
    newParams.branchId = params?.branchId;

    setPageParams(newParams);
  };

  return (
    <IconButtonWithTooltip
      label='Clear filter'
      aria-label='clear-filter-btn'
      Icon={<ClearFilter />}
      onClick={handleClearFilters}
    />
  );
};
