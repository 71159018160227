import { useCustomToast } from '@elkaso-app/web-design';
import { useGetMeApi } from 'apis/auth/use-get-me-api';
import { useMutationWrapper } from 'apis/config/use-api-wrapper';
import http from 'apis/config/vendor-http-service';
import type { AxiosError } from 'axios';
import { useRouteRedirectCorrection } from 'hooks/use-route-redirect-correction';
import type { UseMutationOptions, UseMutationResult } from 'react-query';

const endpoint = 'assign-brand-to-user';
export const assignBrandEndpointIdentifier = endpoint;

interface BodyType {
  vendorEntityId: string;
}

type TData = Record<string, any>;
type TError = AxiosError;

export type TVariables = {
  body: BodyType;
};

type Options = Omit<UseMutationOptions<TData, TError, TVariables, unknown>, 'mutationFn'>;
type ReturnType = UseMutationResult<TData, TError, TVariables>;

export function useAssignBrandApi(options?: Options): ReturnType {
  const toast = useCustomToast();
  const { refetch: refetchMeApi } = useGetMeApi({ enabled: false });
  const { routeRedirectCorrection } = useRouteRedirectCorrection({ onInitiateCall: false });

  const mutationFn = async ({ body }: TVariables) => {
    try {
      const { data } = await http.post(endpoint, body);

      // Re-fetch me api
      refetchMeApi().then(() => {
        // Redirect user to the correct next step
        routeRedirectCorrection();
      });

      return data;
    } catch (error: any) {
      if (!error.response.status || !error.response.data.message) throw error;

      toast({
        description: error.response.data.message,
        status: 'error',
      });

      throw error;
    }
  };

  return useMutationWrapper(mutationFn, { mutationKey: endpoint, ...options });
}
