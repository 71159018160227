import { Button, HStack } from '@elkaso-app/web-design';

export interface INotificationsActionsCell {
  isDisabled: boolean;
}

export const ActionsCell = ({ isDisabled }: INotificationsActionsCell) => {
  const handleRetry = () => {
    console.log('Retry');
  };

  const isLoading = false;
  return (
    <HStack spacing='xs'>
      <Button
        colorScheme='red'
        variant='solid'
        size='sm'
        px='md'
        isDisabled={isDisabled}
        isLoading={isLoading}
        onClick={handleRetry}>
        Retry
      </Button>
    </HStack>
  );
};
